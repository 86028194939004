import React, { useEffect, useState } from 'react';
import { Progress, Typography, Card, Row, Col, Spin } from 'antd';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';

const { Title, Text } = Typography;

const UsagePage = () => {
  const { fetchUserProfile } = useProfilePageHook();
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await fetchUserProfile();
      setUserProfile(profile?.user_profile);
      setLoading(false);
    };

    fetchProfile();
  }, []);

  if (loading) {
    return <Spin size="large" />;
  }

  const usedGenerationCredits = userProfile?.credits?.generation_credit || 0;
  const totalGenerationCredits = userProfile?.credits?.monthly_generation_credit || 0;
  const usedQualificationCredits = userProfile?.credits?.qualification_credit || 0;
  const totalQualificationCredits = userProfile?.credits?.monthly_qualification_credit || 0;

  const plan = `${totalGenerationCredits.toLocaleString()} generation credits/month`;
  const qualificationPlan = `${totalQualificationCredits.toLocaleString()} qualification credits/month`;


  return (
    <div style={{ padding: '24px' }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Title level={4}>Generation credits usage</Title>
            <div style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Progress
                percent={(usedGenerationCredits / totalGenerationCredits) * 100}
                showInfo={false}
                strokeWidth={12}
                strokeColor="#52c41a"
                trailColor="#d9d9d9"
                style={{ width: '80%' }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
              <Text>{(usedGenerationCredits).toLocaleString()} credits remains, of {totalGenerationCredits.toLocaleString()} credits in this billing cycle.</Text>
            </div>
          </Card>
        </Col>

        <Col span={24}>
          <Card>
            <Title level={4}>Qualification credits usage</Title>
            <div style={{ padding: '16px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Progress
                percent={(usedQualificationCredits / totalQualificationCredits) * 100}
                showInfo={false}
                strokeWidth={12}
                strokeColor="#52c41a"
                trailColor="#d9d9d9"
                style={{ width: '80%' }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
              <Text>{(usedQualificationCredits).toLocaleString()} credits remains, of {totalQualificationCredits.toLocaleString()} credits in this billing cycle.</Text>
            </div>
          </Card>
        </Col>        
        <Col span={24}>
          <Card>
            <Title level={4}>Plan Information</Title>
            <div><Text>Plan: {plan}</Text></div>
            <Text>Plan: {qualificationPlan}</Text>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UsagePage;
