import React, { useState } from 'react';
import { Button, Card, Typography, notification } from 'antd';
import { AmazonOutlined } from '@ant-design/icons';
import { getToken } from '../Login/AuthContext';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';

const { Text } = Typography;

const HubspotAuthButton = ({ updateIntegrationStatus, integrationStatus }) => {
  const { disconnectIntegration } = useDocumentPageHook();
  const [isIntegrated, setIsIntegrated] = useState(integrationStatus);

  const getAuthorizationUrl = async () => {
    const token = getToken();
    if (!token) {
      notification.error({
        message: 'User is not authenticated',
        description: 'Please reload the page or logout and try again.',
      });
      return;
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + 'authorize/hubspot/', {
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      console.log(isIntegrated)

      if (response.ok) {
        const width = 500;
        const height = 600;
        const left = (window.screen.width / 2) - (width / 2);
        const top = (window.screen.height / 2) - (height / 2);

        const authWindow = window.open(
            data.authorization_url,
            'HubSpotAuth',
            `width=${width},height=${height},top=${top},left=${left}`
        );

        const pollTimer = window.setInterval(async () => {
          if (authWindow.closed) {
            window.clearInterval(pollTimer);
            const status = await updateIntegrationStatus(); // Update the integration status
            console.log(status);
            setIsIntegrated(status?.hubspot || false);
            if (isIntegrated) {
                notification.success({
                message: 'HubSpot integrated successfully.',
                });
            }
          }
        }, 500);
      } else {
        const data = await response.json();
        notification.error({
          message: 'Authorization Error',
          description: data.error || 'Failed to get authorization URL.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Request Error',
        description: 'Failed to get authorization URL.',
      });
    }
  };

  const handleDisconnect = async () => {
    try {
      await disconnectIntegration('hubspot');
      setIsIntegrated(false);
      updateIntegrationStatus();
    } catch (error) {
      console.error('Failed to disconnect from HubSpot:', error);
    }
  };

  return (
    <Card style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '10px 16px', border: '1px solid #d9d9d9', borderRadius: '16px', marginBottom: '15px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/hub_spot_2.webp" alt="hubspot icon" className="upload-icon" />
        <Typography>
          <Text strong style={{ fontSize: '16px' }}>HubSpot</Text>
          <br />
          <Text style={{ fontSize: '14px', color: '#666' }}>Connect your HubSpot account to our application to sync your data automatically.</Text>
        </Typography>
        <div style={{ marginTop: 20, marginLeft: 15 }}>
          <Button
            danger={isIntegrated ? true : false}
            type='primary'
            onClick={isIntegrated ? handleDisconnect : getAuthorizationUrl}
          >
            {isIntegrated ? 'Disconnect' : 'Connect'}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default HubspotAuthButton;
