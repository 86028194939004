import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, InputNumber, Button, Divider, List, Layout, Modal } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import useProfilePageHook from '../ProfilePage/ProfilePageHook';
import usePricingPageHook from './PricingPageHook';

const { Title, Text, Paragraph } = Typography;

const PricingPage = () => {
  const { usage } = useProfilePageHook();
  const { getProducts, createCheckoutSession, getSubscriptionPage } = usePricingPageHook();
  const [formatPlans, setFormatPlans] = useState([]);
  const [quantity, setQuantity] = useState(1); // default quantity
  const pricePerRecord = 20; // price per data record
  const [totalPrice, setTotalPrice] = useState(quantity * pricePerRecord);

  const updateQuantity = (value) => {
    setQuantity(value);
    setTotalPrice(value * pricePerRecord);
  };  

  useEffect(() => {
    const getProductAndPrice = async () => {
      const product_and_price = await getProducts();
      const formattedPlans = product_and_price.map(product => {
        const activePricing = product.pricings.find(pricing => pricing.is_active);
        return {
          planTitle: product.name,
          priceId: activePricing.stripe_price_id,
          price: activePricing.price,
          currency: activePricing.currency,
          // Other properties and features can be formatted here
        };
      });
      setFormatPlans(formattedPlans);
    };
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('status') === 'success') {
      // Display the success modal
      Modal.success({
        title: 'Payment Successful',
        content: 'Your payment was successful. Thank you for your purchase.',
      });
      // Clear the URL parameter
      urlParams.delete('status');
      window.history.replaceState(null, null, "?" + urlParams.toString());      
    }    
    getProductAndPrice();
  }, []);  // Add an empty dependency array to prevent infinite loop

  const createCheckout = async (plan) => {
    if (plan.planTitle === 'Pay as you go') {
      await createCheckoutSession(plan.priceId, quantity);
    } else {
      await createCheckoutSession(plan.priceId, 1);
    }
};

  const isCurrentPlan = (planTitle) => {
    return usage.subscription_plan === planTitle;
  };

  const renderPrice = (plan) => (
    <div>
      <Paragraph style={{marginBottom: '0px'}}>
        {plan.title === 'Basic' && (
          <>
            <Text delete style={{ fontSize: '24px', color: 'gray', marginRight: 8 }}>
              $80
            </Text>
            <Text strong style={{ fontSize: '24px' }}>
              $40 / Month
            </Text>
          </>
        )}
        {plan.title === 'Free (Beta Special!)' && (
          <Text strong style={{ fontSize: '24px' }}>$0 / month</Text>
        )}        
        {plan.title === 'Pay as you go' && (
          <>
            <Text strong style={{ fontSize: '24px' }}>${totalPrice.toFixed(2)}</Text>            
          </>
        )}
      </Paragraph>
      <Paragraph>
        {plan.title === 'Basic' && <Text strong style={{ fontSize: '12px' }}>Monthly plan</Text>}
        {plan.title === 'Pay as you go' && <Text strong style={{ fontSize: '12px'}}>$20 per full analysis</Text>}
      </Paragraph>

      <Paragraph>
      {plan.title === 'Pay as you go' &&
        <div>              
              <Button onClick={() => updateQuantity(quantity - 1)}>-</Button>
              <InputNumber
                min={1}
                value={quantity}
                onChange={updateQuantity}
                style={{ margin: '0 8px' }}
                readOnly={true}
              />
              <Button onClick={() => updateQuantity(quantity + 1)}>+</Button>
            </div>        
        }        
      </Paragraph>
      
    </div>
  );


  const renderFeaturesList = (features) => (
    <List
      size="medium"
      dataSource={features}
      renderItem={item => (
        <List.Item>
          <CheckOutlined style={{ color: '#52c41a' }} /> <Text>{item}</Text>
        </List.Item>
      )}
      split={false}
    />
  );

  const plans = [
    {
      planTitle: 'Free',
      title: 'Free (Beta Special!)',
      subtitle: 'For our beta users.',
      features: [
        ' 1 Full Analysis credits & 3 Basic Analysis credits',        
      ],
      buttonText: usage.subscription_plan === 'Free' ? 'Current Plan' : 'Select Plan',
      buttonType: 'default',
      disabled: usage.subscription_plan === 'Free',
      priceId: formatPlans.find(plan => plan.planTitle === 'Free Plan')?.priceId || ''
    },
    {
      planTitle: 'Pay as you go',
      title: 'Pay as you go',
      subtitle: 'Buy a fixed number of full analysis.',
      features: [
        'Credits are valid for 6 month',
        'Get 5 times as many basic analysis credits',
      ],
      buttonText: 'Proceed to checkout',
      buttonType: 'default',
      priceId: formatPlans.find(plan => plan.planTitle === 'Dealight Pay as you go')?.priceId || ''
    },    
    {
      planTitle: 'Basic',      
      title: 'Basic',
      subtitle: 'Best if you are looking for investor matchmaking.',
      description: 'Pay monthly',
      features: [
        '2 Full Analysis credits & 10 Basic Analysis credits',
        '1 Application submission per month',
        'Credits are renewed per month',
        'Matchmake up to 10 investors per month',
        'Technical support',
      ],
      buttonText: 'Coming Soon',
      buttonType: 'default',
      disabled: true,
      priceId: formatPlans.find(plan => plan.planTitle === 'Dealight Basic Plan')?.priceId || ''
    }
  ];

  return (
    <Layout>
      <Title level={2} style={{textAlign:'center'}}>Plans and Pricing</Title>
    <Row gutter={[16, 16]} justify="center" style={{ padding: '45px' }}>
      {plans.map((plan) => {
        const isCurrent = isCurrentPlan(plan.planTitle);
        return (
          <Col key={plan.title} span={7}>
            <Card
              bordered={false}
              style={{
                textAlign: 'left',
                boxShadow: '0 4px 8px 0 rgba(0,0,0,0.1)',
                transition: '0.3s',
                borderRadius: '5px',
                padding: '4px',
                backgroundColor: '#ffffff',
              }}
              
            >
              <Title level={3}>{plan.title}</Title>
              <Text>{plan.subtitle}</Text>
              <Divider />
              {renderPrice(plan)}
              {renderFeaturesList(plan.features)}
              {plan.planTitle !== 'Free' && (
                <Button
                  type={'primary'}
                  block
                  disabled={plan.disabled}
                  onClick={() => createCheckout(plan)}
                  style={{ marginTop: '20px' }}>
                    {plan.buttonText}
                </Button>
              )}
            </Card>
          </Col>
        );
      })}
      <Row>
      <Col span={16} style={{ textAlign: 'center' }}>
        <Title level={4}>Your Current Plan: {usage.subscription_plan}</Title>
        </Col>       
        <Col span={8} style={{ textAlign: 'center' }}>         
        <Button
          type={'primary'}
          block
          disabled={true}
          onClick={() => getSubscriptionPage()}
          style={{marginLeft: '20px', marginTop: '23px'}}
        >Manage Plan</Button>
      </Col>
      </Row>
    </Row>
    </Layout>    
  );
};

export default PricingPage;


   
