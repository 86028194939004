import React, { useState, useEffect } from 'react';
import { Button, Table, Popover, message, Tag, Typography } from 'antd';
import { UploadOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import URLUploadPopover from './URLUploadPopover';
import ImportCSVModal from './ImportCSVModal';
import AnalyzeURLPopover from './AnalyzeURLPopover';
import CompanyDetailsPage from './CompanyDetailsPage';

import './UploadLeadsPage.css';  // Assume we are using a CSS file for styles

const { Text } = Typography;

const LeadsUploadPage = () => {
  const { analyzeURLs, uploadNewURLs, deleteURLs, pollSessionDetails, getSessionDetails, sessionDetails } = useDocumentPageHook();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);

  useEffect(() => {
    const fetchSessionDetails = async () => {
      const urls = await getSessionDetails();
      if (urls) {
        setSelectedRowKeys([]);
      } else {
        message.error('Failed to load companies. Try again later');
      }
    };
    fetchSessionDetails();
  }, []);

  const handleUploadURLs = async (urls) => {
    try {
      await uploadNewURLs(urls);
      await getSessionDetails();
    } catch (error) {
      console.log(error);
      message.error('Failed to upload URLs');
    }
  };

  const handleDeleteSelectedURLs = async () => {
    try {
      await deleteURLs(selectedRowKeys);
      setSelectedRowKeys([]);
      await getSessionDetails();
    } catch (error) {
      message.error('Failed to delete URLs');
    }
  };

  const handleAnalyzeURLs = async () => {
    try {
      await analyzeURLs();
      pollSessionDetails();
    } catch (error) {
      message.error('Failed to analyze URLs');
    }
  };

  const handleViewDetails = async (record) => {
    const companyData = record.company_data;
    if (companyData) {
      setSelectedLead(record);
      setDetailsModalVisible(true);
    } else {
      message.error('Failed to fetch company details');
    }
  };

  const renderState = (state, record) => {
    switch (state) {
      case 1:
        return <Tag color="yellow">Processing</Tag>;
      case 2:
        return <Tag color="green">Complete</Tag>;
      case 3:
        return <Tag color="red">Fail</Tag>;
      default:
        return <Tag color="blue">New</Tag>;
    }
  };

  const columns = [
    {
      title: 'Name (required)',
      dataIndex: 'contact_person_name',
      key: 'contact_person_name',
    },
    {
      title: 'Title',
      dataIndex: 'contact_person_title',
      key: 'contact_person_title',
    },
    {
      title: 'Email (required)',
      dataIndex: 'contact_person_email',
      key: 'contact_person_email',
    },
    {
      title: 'Company (required)',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text, record) => (record.url ? <a href={record.url} target="_blank" rel="noopener noreferrer">{record.url}</a> : text)
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (state, record) => renderState(state, record),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        record.state === 2 ? (
          <Button
            icon={<InfoCircleOutlined />}
            onClick={() => handleViewDetails(record)}
          >
            Details
          </Button>
        ) : null
      )
    }
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  return (
    <div className="my-leads-page">
      <div className="action-buttons">
        <Button
          type="default"
          icon={<DeleteOutlined />}
          onClick={handleDeleteSelectedURLs}
          disabled={selectedRowKeys.length === 0}
          className="action-button"
        >
          Delete Selected
        </Button>
        <ImportCSVModal onSubmit={handleUploadURLs} />
        <div className="right-aligned-button">
          <AnalyzeURLPopover onAnalyze={handleAnalyzeURLs} newURLCount={sessionDetails?.filter(result => result.state === 0).length || 0} />
        </div>
      </div>

      <div className="content-section">
        <Table
          columns={columns}
          dataSource={sessionDetails}
          rowKey="url_id"
          rowSelection={rowSelection}
          pagination={{ pageSize: 100 }}
        />
      </div>

      <CompanyDetailsPage
        visible={detailsModalVisible}
        onClose={() => setDetailsModalVisible(false)}
        record={selectedLead}
        showEmail={true}
      />
    </div>
  );
};

export default LeadsUploadPage;


