import React, { useState, useEffect } from 'react';
import { Button, Card, Typography, Space } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { getToken } from '../Login/AuthContext';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';

import Notification from '../Notification/NotificationMessage';

const { Text } = Typography;


const GoogleAuthButton = ({ updateIntegrationStatus, integrationStatus }) => {
  const { disconnectIntegration } = useDocumentPageHook();  
  const [isIntegrated, setIsIntegrated] = useState(integrationStatus);

  const getAuthorizationUrl = async () => {
    const token = getToken();
    if (!token) {
      Notification.error('User is not authenticated', 'Please reload the page or logout and try again.');
      return;
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + 'authorize_google/', {
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (response.ok) {
        const width = 500;
        const height = 600;
        const left = (window.screen.width / 2) - (width / 2);
        const top = (window.screen.height / 2) - (height / 2);

        const authWindow = window.open(
          data.authorization_url,
          'GoogleAuth',
          `width=${width},height=${height},top=${top},left=${left}`
        );

        const pollTimer = window.setInterval(() => {
          if (authWindow.closed) {
            window.clearInterval(pollTimer);
            setIsIntegrated(true);
            updateIntegrationStatus();
            Notification.complete('Gmail integrated successfully.');
          }
        }, 500);
      } else {
        Notification.error('Authorization Error', data.error || 'Failed to get authorization URL.');
      }
    } catch (error) {
      Notification.error('Request Error', 'Failed to get authorization URL.');
    }
  };

  const handleDisconnect = async () => {
    try {
      await disconnectIntegration('gmail');
      setIsIntegrated(false);
      updateIntegrationStatus();
    } catch (error) {
      console.error('Failed to disconnect from Gmail:', error);
    }
  };

  return (
    <Card style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '10px 16px', border: '1px solid #d9d9d9', borderRadius: '16px', marginBottom: '15px' }}>
    <div style={{ display: 'flex', alignItems:'center'}}>
    <MailOutlined style={{ fontSize: '32px', color: '#1890ff', marginRight: '16px' }} />
        <Typography>
        <Text strong style={{ fontSize: '16px' }}>Gmail</Text>
        <br />
        <Text style={{ fontSize: '14px', color: '#666' }}>Connect your Gmail account to our application to send and receive emails automatically.</Text>
        </Typography>

    <div style={{ marginTop:20, marginLeft:15 }}>
        <Button
        danger={isIntegrated ? true : false}
        type='primary'
        onClick={isIntegrated ? handleDisconnect : getAuthorizationUrl}
        >
        {isIntegrated ? 'Disconnect' : 'Connect'}
        </Button>
    </div>
    </div>    
    </Card>
  );
};

export default GoogleAuthButton;


