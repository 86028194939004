import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Tooltip, Table, Image, Space, Divider, Row, Col, Typography, Tag, Card, Button, message, Descriptions } from 'antd';
import { LinkedinOutlined } from '@ant-design/icons';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip as RechartsTooltip, Legend, Cell } from 'recharts';

const { TabPane } = Tabs;
const { Title, Paragraph, Text } = Typography;

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6361', '#BC5090', '#58508D', '#003F5C'];

const CompanyDetailsPage = ({ visible, onClose, record, showEmail }) => {
  const [activeKey, setActiveKey] = useState("2");
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  useEffect(() => {
    if (visible) {
      setActiveKey("2");
      setIsDescriptionExpanded(false);
    }
  }, [visible]);

  if (!record) return null;
  const lead = record.company_data;

  const logo_name = (
    <>
      <Space>
        <Image
          width={40}
          height={40}
          src={lead.company_logo_url || '/person_icon.png'}
          style={{ borderRadius: '50%' }}
          alt="logo"
        />
        {lead.company_name}
      </Space>
    </>
  );

  const specialties = lead.additional_info?.company_services?.map(service => (
    <Tag key={service.id} color="blue">{service.specialty}</Tag>
  ));

  const recentPosts = lead.additional_info?.recent_updates?.map(post => (
    <Card key={post.id} style={{ marginBottom: 16 }}>
      <Paragraph>{logo_name}:</Paragraph>
      <Paragraph>{post.description}</Paragraph>
      <Paragraph>
        <span style={{ float: 'right' }}><strong>Date:</strong> {post.date} ago</span>
      </Paragraph>
    </Card>
  ));

  const truncateContent = (content, maxLength = 35) => {
    if (!content) return '';
    return content.length > maxLength ? content.slice(0, maxLength) + '...' : content;
  };

  const DescriptionItem = ({ title, content }) => (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8, marginTop: 8 }}>
      <Text strong style={{ marginRight: 8 }}>{title}</Text>
      <Tooltip title={content}>
        <Text>{truncateContent(content)}</Text>
      </Tooltip>
    </div>
  );

  const description = lead.company_description;
  const truncatedDescription = description && description.length > 100 ? `${description.substring(0, 100)}...` : description;

  const handleDescriptionExpand = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  const headcountData = Object.entries(lead.additional_info.headcount_research || {}).map(([key, value]) => ({
    name: key.replace(/_/g, ' '),
    headcount: value, // Change "value" to "headcount"
  }));
  
  const filteredData = headcountData.filter(entry => entry.headcount !== 0);
  const truncate = (str, maxLength = 10) => {
    return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
  };  

  return (
    <Modal
      title={logo_name}
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={600}
      bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }} // Allow scrolling if content overflows
    >
      <Tabs defaultActiveKey="2" activeKey={activeKey} onChange={setActiveKey}>
        <TabPane tab="Company" key="2">
          <Row style={{ margin: 0, padding: 0 }}>
            <Col span={14}>
              <DescriptionItem title="Company Size:" content={lead.company_size} />
            </Col>
            <Col span={10}>
              <DescriptionItem title="Founded:" content={lead.company_founded} />
            </Col>
            <Col span={14}>
              <DescriptionItem title="Website:" content={<a href={lead.company_website_url} target="_blank" rel="noopener noreferrer">{lead.company_website_url}</a>} />
            </Col>
            <Col span={10}>
              <DescriptionItem title="Linkedin:" content={<a href={lead.company_linkedin_url} target="_blank" rel="noopener noreferrer"><LinkedinOutlined style={{ fontSize: '25px', color: '#0077b5' }} /></a>} />
            </Col>
            <Col span={14}>
              <DescriptionItem title="Industry:" content={lead.company_industry} />
            </Col>
            <Col span={10}>
              <DescriptionItem title="Specialties:" content={specialties} />
            </Col>
            <Col span={24}>
            <ResponsiveContainer width="100%" height={300} style={{ marginRight: 0 }}>
              <BarChart
                data={filteredData}
                layout="vertical" // This makes the chart horizontal
                margin={{
                  top: 5,
                  right: 20,
                  left: -50,
                  bottom: 5,
                }}
              >
                <XAxis type="number" />
                <YAxis
                  type="category"
                  dataKey="name"
                  tickFormatter={(name) => truncate(name, 10)} // Truncate long names
                  width={150} // Adjust width as needed
                />
                <RechartsTooltip />
                <Legend />
                <Bar dataKey="headcount" fill="#8884d8" label={({ value }) => `${value} headcount`} >
                  {filteredData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
            </Col>
            <Col span={24}>
              <Text strong>Description:</Text>
              <div>
                <Text>
                  {isDescriptionExpanded ? description : truncatedDescription}
                </Text>
                {description && description.length > 50 && (
                  <Button type="link" onClick={handleDescriptionExpand}>
                    {isDescriptionExpanded ? 'Show less' : 'Learn more'}
                  </Button>
                )}
              </div>
            </Col>            
          </Row>
          <Title level={5} style={{ marginTop: '16px' }}>Recent Posts</Title>
          <div>{recentPosts}</div>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default CompanyDetailsPage;
