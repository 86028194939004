import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, Statistic, Tooltip, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import Notification from '../Notification/NotificationMessage';

const NewTaskModal = ({ onInitiateTask, maxURLCount, isProcessing, outOfCredit, dailyQuota, modalVisible, setModalVisible }) => {
  const navigate = useNavigate();
  const { getCampaignOverview, getBusinessContext } = useDocumentPageHook();
  const [urlCount, setUrlCount] = useState(1);
  const [isInitiating, setIsInitiating] = useState(false);
  const [totalUrlsToAnalyze, setTotalUrlsToAnalyze] = useState('Loading...');
  const [campaignId, setCampaignId] = useState(null);

  useEffect(() => {
    if (modalVisible) {
      fetchBusinessContext();
    }
  }, [modalVisible]);  

  const fetchCampaignOverview = async (campaignId) => {
    setTotalUrlsToAnalyze('Loading...');
    const data = await getCampaignOverview(campaignId);
    if (data && data.total_urls_to_process !== undefined) {
      setTotalUrlsToAnalyze(data.total_urls_to_process);
    } else {
      Notification.error('Failed to load campaign overview. Try again later');
    }
  };

  const fetchBusinessContext = async () => {
    const contextData = await getBusinessContext();
    if (contextData && contextData.campaign && contextData.campaign.id) {
      setCampaignId(contextData.campaign.id);
      fetchCampaignOverview(contextData.campaign.id);
    } else {
      Notification.error('Failed to load business context. Try again later');
    }
  };

  const handleInitiate = async () => {
    setIsInitiating(true);
    await onInitiateTask(urlCount);
    setIsInitiating(false);
    setModalVisible(false); // Close the modal on successful initiation
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <Modal
      title="Initiate Task"
      open={modalVisible}
      onCancel={handleModalClose}
      footer={[
        <Button key="cancel" onClick={handleModalClose}>
          Cancel
        </Button>,
        <Button
          key="run"
          type="primary"
          onClick={handleInitiate}
          disabled={isInitiating || totalUrlsToAnalyze === 0}
        >
          Initiate Analysis
        </Button>,
      ]}
    >
      <Card bordered={false} style={{ width: '100%' }}>
        <p style={{ marginTop: 0, marginBottom: 15 }}>
          DealightAI follows instructions in your sales agent setting.
        </p>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Tooltip title="The total number of potential leads available for analysis.">
              <Statistic title="Potential Leads to Analyze:" value={totalUrlsToAnalyze} />
            </Tooltip>
          </Col>
          <Col span={12}>
            <Tooltip title="Your daily limit for relevant leads researched.">
              <Statistic title="Daily Analysis Quota:" value={dailyQuota} />
            </Tooltip>
          </Col>
        </Row>
      </Card>
    </Modal>
  );
};

export default NewTaskModal;
