import React, { useState } from 'react';
import { Table, Row, Col, Typography, Tag, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import ImportCSVModal from '../LeadsPage/ImportCSVModal';
import CompanyTargetPage from './CompanyTargetPage';


const ImportCSVPage = () => {
  const location = useLocation();
  const [uploadedData, setUploadedData] = useState(location.state?.data || []);
  const [fileName, setFileName] = useState(location.state?.fileName || '');
  const [showModal, setShowModal] = useState(false);

  const handleCSVSubmit = (data, uploadedFileName) => {
    setUploadedData(data);
    setFileName(uploadedFileName);
    setShowModal(false);
  };


  return (
    <CompanyTargetPage
      mode="import"
      data={{ fileName: fileName, uploadedData: uploadedData }} // Provide your data here
      onCSVSubmit={handleCSVSubmit}
    />
  );
};

export default ImportCSVPage;
