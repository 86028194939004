import React, { useState, useEffect } from 'react';
import { Popover, Button, Card, Select, Statistic } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';

const { Option } = Select;

const QualifyLeadsPopover = ({ uploadedData, isProcessing, outOfCredit }) => {
  const navigate = useNavigate();
  const { analyzeURLs, pollSystemTasks } = useDocumentPageHook();

  const [urlCount, setUrlCount] = useState(1);
  const [isInitiating, setIsInitiating] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [totalValidatedUrls, setTotalValidatedUrls] = useState(0);

  useEffect(() => {
    // Calculate total validated URLs directly from uploadedData
    setTotalValidatedUrls(uploadedData.length);
  }, [uploadedData]);

  const handleInitiate = async () => {
    setIsInitiating(true);
    const session_id = await analyzeURLs(uploadedData, urlCount, totalValidatedUrls);
    if (session_id) {
      pollSystemTasks(session_id);
    }
    setIsInitiating(false);
    setPopoverVisible(false); // Close the popover on successful initiation
  };

  const handleChange = value => {
    setUrlCount(value);
  };

  const handlePopoverVisibleChange = visible => {
    setPopoverVisible(visible);
  };

  return (
    <Popover
      content={
        <Card title="Qualify Leads" bordered={false} style={{ width: 300 }}>
          <p style={{ marginTop: 0, marginBottom: 15 }}>
            DealightAI will qualify the leads based on the settings in your campaign.
          </p>
          <Statistic title="Total Validated Leads:" value={totalValidatedUrls} />
          <p>Select the number of URLs to qualify:</p>
          <Select defaultValue={1} style={{ width: '100%', marginBottom: 15 }} onChange={handleChange}>
            {[1, 10, 50, 100, 500, 1000].map(value => (
              <Option key={value} value={value}>
                {value} URL{value > 1 ? 's' : ''}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={handleInitiate}
            style={{ width: '100%', marginTop: '0px' }}
            disabled={isInitiating || totalValidatedUrls === 0}
          >
            Qualify selected URLs ({urlCount})
          </Button>
        </Card>
      }
      trigger="click"
      visible={popoverVisible}
      onVisibleChange={handlePopoverVisibleChange}
    >
      <Button type="primary" icon={<PlayCircleOutlined />} disabled={isProcessing || outOfCredit}>
        Qualify Leads
      </Button>
    </Popover>
  );
};

export default QualifyLeadsPopover;