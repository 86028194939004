import React, { createContext, useContext, useState, useCallback } from 'react';
import axios from 'axios';
import { getToken } from '../Login/AuthContext';

const DocumentContext = createContext();

export const useDocument = () => {
  return useContext(DocumentContext);
};

export const DocumentProvider = ({ children }) => {
  const [businessContext, setBusinessContext] = useState([]);
  const [sessionDetails, setSessionDetails] = useState([]);
  const [systemTasks, setSystemTasks] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [isTaskPolling, setIsTaskPolling] = useState(false);
  const [pollTime, setPollTime] = useState(0);

  const getSystemTasks = async (sessionId) => {
    const token = getToken(); // Assume this function retrieves the current user's auth token
    if (!token) {
        console.error('User is not authenticated');
        return null;
    }
  
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}system_session_details/${sessionId}/`, {
            headers: {
                'Authorization': `Token ${token}`
            },
        });
        setSystemTasks(response.data);
        return response.data;
    } catch (error) {
        console.error('Error getting session details:', error.response ? error.response.data : error);
        return null;
    }
  };

  const getSystemSessions = async () => {
    const token = getToken(); // Assume this function retrieves the current user's auth token
    if (!token) {
        console.error('User is not authenticated');
        return null;
    }
  
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}research_sessions/`, {
            headers: {
                'Authorization': `Token ${token}`
            },
        });
        setSessions(response.data);
        return response.data;
    } catch (error) {
        console.error('Error getting session details:', error.response ? error.response.data : error);
        return null;
    }
  };   
   

  const pollSystemTasks = useCallback(async (session_id) => {
    if (isTaskPolling) {
      console.log('is polling, return')
      return; // Prevent multiple polling instances
    }
  
    setIsTaskPolling(true);
    setPollTime(0);
    try {
      while (true) {
        setPollTime(null);
        const tasks = await getSystemTasks(session_id);
        const session = await getSystemSessions();
        const isComplete = tasks?.is_complete;
        setPollTime(1);
        if (isComplete === true) {
          // Stop polling if no entry is in PROCESSING state
          console.log('stop polling')
          break;
        }
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
    } catch (error) {
      console.error('Error polling session details:', error);
    } finally {
      setIsTaskPolling(false);
    }
}, [isTaskPolling, getSystemTasks]);     

  const value = {
    businessContext,
    setBusinessContext,
    sessionDetails,
    setSessionDetails,
    systemTasks,
    setSystemTasks,
    pollSystemTasks,
    getSystemTasks,
    sessions,
    setSessions,
    pollTime,
  };

  return (
    <DocumentContext.Provider value={value}>
      {children}
    </DocumentContext.Provider>
  );
};
