import React, { useState, useEffect } from 'react';
import { Form, Button, Spin, message, Typography, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import CompanyInfoForm from '../HomePage/CompanyInfoForm';
import Notification from '../Notification/NotificationMessage';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import './PreferencePage.css';

const { Title } = Typography;

const CompanyInfoPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { createBusinessContext, getBusinessContext } = useDocumentPageHook();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    async function fetchAndSetBusinessContext() {
      setIsLoading(true);
      try {
        const response = await getBusinessContext();
        if (response) {
          form.setFieldsValue({
            businessName: response.business_name,
            businessDescription: response.business_description,
            founderLinkedinUrl: response.founder_linkedin_url,
            businessCompanyUrl: response.business_company_url,
          });
          setIsEditMode(false);
          setInitialLoad(false);
        } else {
          setIsEditMode(true);
        }
      } catch (error) {
        console.error('Failed to fetch business context:', error);
        Notification.error('Failed to load existing information. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
    fetchAndSetBusinessContext();
  }, [form]);

  const handleEditClick = () => {
    if (isEditMode) {
      form.validateFields().then(handleSubmit).catch(handleValidationError);
    } else {
      setIsEditMode(true);
    }
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('business_name', values.businessName);
    if (values.businessDescription) {
      formData.append('business_description', values.businessDescription);
    }
    if (values.businessLinkedin) {
      formData.append('business_linkedin_url', values.businessLinkedin);
    }
    if (values.businessCompanyUrl) {
      formData.append('business_company_url', values.businessCompanyUrl);
    }

    try {
      await createBusinessContext(formData);
      setIsEditMode(false);
    } catch (error) {
      console.error('Failed to create business context:', error);
      Notification.error('Failed to save business context. Please try again.');
    }
  };

  const handleValidationError = (errorInfo) => {
    if (errorInfo.errorFields && errorInfo.errorFields.length > 0) {
      const errorMessage = errorInfo.errorFields.map(field => `${field.name[0]}: ${field.errors[0]}`).join(', ');
      message.error(errorMessage);
    } else {
      console.log(errorInfo)
      message.error('Form submission failed');
    }
  };

  return (
    <div className="preference-page">
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <div style={{
          minWidth: '792px',
          margin: '0 auto',
          padding: '22px 66px',
          backgroundColor: '#fff',
          borderRadius: '16px',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '0'
        }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={3}>Company Information</Title>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={handleEditClick}
                style={{ marginRight: '8px', marginTop:'15px' }}
              >
                {isEditMode ? 'Save' : 'Edit'}
              </Button>
            </Col>
          </Row>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            disabled={!isEditMode}
          >
            <CompanyInfoForm form={form} />
          </Form>
        </div>
      )}
    </div>
  );
};

export default CompanyInfoPage;
