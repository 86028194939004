import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { useAuth } from './AuthContext';
import { getToken } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import Notification from '../Notification/NotificationMessage';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

import $ from 'jquery';
import './LoginPage.css';  // Import the CSS file

const LoginPage = () => {
    const token = getToken();
    const navigate = useNavigate();
    const { 
        isLoggedIn, setIsLoggedIn, 
        showLoginModal, setShowLoginModal, 
        username, setUsername, 
        password, setPassword, 
        setToken, validateToken,
      } = useAuth();  

    useEffect(() => {
        const checkToken = async () => {
            const isValid = await validateToken();
            if (isValid) {
              navigate('/getting_started');
            }
          };
          if (token) {
            checkToken();
          }
    }, [navigate, validateToken]);

    const handleLogin = (email, password) => {
        $.ajax({
            url: process.env.REACT_APP_API_URL + `login/`,
            method: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({ email, password }),
            success: function(data) {
                if (data.token) {
                    setToken(data.token);
                    localStorage.setItem('jwtToken', data.token);
                    navigate('/getting_started');
                } else {
                    Notification.error('Invalid username or password');
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                Notification.error('Authentication failed');
            }
        });
    };

    const handleGoogleLogin = async (response) => {
        $.ajax({
            url: process.env.REACT_APP_API_URL + `google_login/`,
            method: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({"auth_token": response.credential}),
            success: function(data) {
                if (data.token) {
                    setToken(data.token);
                    localStorage.setItem('jwtToken', data.token);
                    setIsLoggedIn(true);
                    setShowLoginModal(false);
                    navigate('/getting_started');
                } else {
                    console.error("Authentication failed!");
                    Notification.error('Google authentication failed');
                    // Handle authentication errors as needed
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                let errorMessage = 'Google authentication failed';
                if (jqXHR.responseJSON && jqXHR.responseJSON.error) {
                    errorMessage = jqXHR.responseJSON.error;
                }
                Notification.error(errorMessage, '');
                console.error("An error occurred:", errorThrown);
            }
        });
    };    
    
    const onFinish = (values) => {
        setUsername(values.email);
        setPassword(values.password);        
        handleLogin(values.email, values.password);
    };  

    const onFinishFailed = (errorInfo) => {
        Notification.error('Invalid username or password format');
    };

    return (
    <div className="login-container">
        <div className="login-box">
        <div className="login-logo" style={{marginTop: '15px'}}>
            <img src="/logo_with_name.png" alt="Dealight Logo" />
        </div>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100% - [height of your logo and other elements])'
        }}>
            <h2>Log In</h2>
        </div>
        <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="login-form"
        >
            <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
            >
            <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
            >
            <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked" className="remember-me">
            <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item className="login-btn-item">
            <Button type="primary" htmlType="submit" className="login-btn">
                Log In
            </Button>
            </Form.Item>
            <GoogleOAuthProvider clientId='240031371683-jik17kcvdjn64kqpsoq2k4eaesrank3k.apps.googleusercontent.com'>
                <GoogleLogin
                    onSuccess={handleGoogleLogin}
                    onError={() => {
                        Notification.error('Google login failed');
                    }}
                />
            </GoogleOAuthProvider>            
        </Form>
        </div>
    </div>
    );
}

export default LoginPage;

