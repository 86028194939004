import React, { useState, useEffect } from 'react';
import { List, Avatar, Layout, Card, Button, Input, Form, Table, Row, Space, Col, Typography, Tooltip, Tag, Divider, Popover, Menu, Dropdown } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Add CSS for the editor

import './Inbox.css';
import OutReachDetailsPage from '../LeadsPage/OutReachDetailsPage';
import DetailsDrawer from '../LeadsPage/DetailsDrawer';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';

const { Sider, Content } = Layout;
const { TextArea } = Input;

const PendingInbox = () => {
  const { getReachOutDetails, getReachOutReachEmail, approveAndSendInitialMessage } = useDocumentPageHook();

  const [data, setData] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [messageText, setMessageText] = useState('hello');
  const [messageVersion, setMessageVersion] = useState('long');
  const [followUpType, setFollowUpType] = useState('initial');
  const [emailTone, setEmailTone] = useState('professional');
  

  const toggleDrawer = (record) => {
    if (record == selectedThread) {
      if (drawerVisible) {
        setDrawerVisible(false);
      } else{
        setDrawerVisible(true);
      }
    } else {
      setDrawerVisible(true);
    }  
  };

  const onDrawerClose = () => {
    setDrawerVisible(false);
  };    

  const [form] = Form.useForm();

  const fetchData = async () => {
    const details = await getReachOutDetails('pending');
    if (details) {
      setData(details?.url_details);
    }
  };  
  useEffect(() => {
    fetchData();
  }, []);

  const handleThreadSelect = (thread) => {
    setSelectedThread(thread);
    form.setFieldsValue({
        subject: '',
    });    
    setMessageText('')
    if (thread.email_threads?.messages?.length > 0) {
      const firstMessage = thread.email_threads.messages[0];
      form.setFieldsValue({
        subject: firstMessage.subject,
      });
      setMessageText(firstMessage.body)
    }
  };

  const fetchReachOutEmail = async (emailTone) => {
    setEmailTone(emailTone)
    setIsLoading(true);
    const email_content = await getReachOutReachEmail(selectedThread.url_id, emailTone);
    form.setFieldsValue({
      subject: email_content.title,
    });
    setMessageText(email_content.message)
    setIsLoading(false);
  }


  const handleApprove = async () => {
    const values = await form.validateFields();
    const email_detail_id = selectedThread.email_threads.email_id;
    //console.log(messageText)
    setIsLoading(true);
    await approveAndSendInitialMessage(email_detail_id, values.subject, messageText);
    setSelectedThread(null);
    setIsLoading(false);
    await fetchData();
  };


  const renderFollowUpMessages = (messages) => {
    const formattedMessages = messages?.slice(0, 2).map((message, index) => (
      <div key={index} style={{ marginBottom: 16 }}>
        <p>{message.split('\n').map((part, idx) => (
          <span key={idx}>
            {part}
            <br />
          </span>
        ))}</p>
        <Divider />
      </div>
    ));
  
    return <div>{formattedMessages}</div>;
  };
  

  const contactInfo = selectedThread?.contact_person_data


  const personProfile = (
    <>
      <div className="thread-header" style={{ marginBottom: '20px' }}>
      <Avatar
            src={contactInfo?.logo_url || '/person_icon.png'}
            style={{ borderRadius: '50%' }}
            alt="person"
          />
        <Button 
          style={{ color: '#1890ff', textDecoration: 'underline', cursor: 'pointer', marginLeft:5, marginTop:0 }}
          onClick= {()=>{
              setSelectedLead(selectedThread);
              toggleDrawer(selectedThread);
            }  
          }
        >
          {contactInfo?.name || 'NA'}
        </Button>
      </div>    
    </>
  )

  const lengthMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setMessageVersion('long')}>Long version</Menu.Item>
      <Menu.Item disabled key="2" onClick={() => setMessageVersion('short')}>More to come</Menu.Item>
      {/* Add more variables here */}
    </Menu>
  );
  
  const messageTypeMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setFollowUpType('initial')}>Initial Message</Menu.Item>
      <Menu.Item key="2" onClick={() => setFollowUpType('1st')}>1st follow up</Menu.Item>
      <Menu.Item key="3" onClick={() => setFollowUpType('2nd')}>2nd follow up</Menu.Item>
      <Menu.Item key="4" onClick={() => setFollowUpType('3rd')}>3rd follow up</Menu.Item>

      {/* Add more icebreakers here */}
    </Menu>
  );  

  const emailToneMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => fetchReachOutEmail('professional')}>Professional</Menu.Item>
      <Menu.Item key="2" onClick={() => fetchReachOutEmail('casual')}>Casual</Menu.Item>
      <Menu.Item key="3" onClick={() => fetchReachOutEmail('direct')}>Direct</Menu.Item>
      <Menu.Item key="4" onClick={() => fetchReachOutEmail('empathetic')}>Empathetic</Menu.Item>
      <Menu.Item key="5" onClick={() => fetchReachOutEmail('technical')}>Technical</Menu.Item>
      {/* Add more icebreakers here */}
    </Menu>
  );  
  const convertNewlinesToHtml = (text) => {
    return text.replace(/\n\n/g, '<br>').replace(/\n/g, '<br>');
  };
  

  return (
    <Layout style={{ height: '90vh', padding: '20px', overflowY: 'visible' }}>
      <Sider width={300} style={{ background: '#fff', padding: '20px', borderRight: '1px solid #d9d9d9', overflowY: 'auto' }}>
        <List
          itemLayout="horizontal"
          //dataSource={data.sort((a, b) => b.company_data?.relevance.relevance - a.company_data?.relevance.relevance)}
          dataSource={data}
          renderItem={item => (
            <List.Item
            className={`clickable-item ${selectedThread === item ? 'selected' : ''}`}
            onClick={() => handleThreadSelect(item)}
            style={selectedThread === item ? { backgroundColor: '#f5f5f5' } : {}}
          >
            <List.Item.Meta
                avatar={<Avatar src={item.contact_person_data?.logo_url || '/person_icon.png'} />}
                title={
                  item.contact_person_data?.name || 'Unknown Name'
                }
                description={
                  <div>
                    <Tag>
                      {item?.company_name}
                    </Tag>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Sider>
      <Layout style={{ height: '81vh', overflowY: 'hidden' }}>
        <Content style={{ padding: '20px', background: '#fff', overflowY: 'hidden' }}>
          {selectedThread ? (
            <>
              <DetailsDrawer visible={drawerVisible} onClose={onDrawerClose} record={selectedLead} />
              
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form form={form} layout="vertical">
                    <Form.Item name="subject" label={personProfile}>
                      <Input
                        prefix={<span style={{ color: '#a9a9a9', marginRight: '8px' }}>Subject:</span>}
                      />
                    </Form.Item>

                    {/* Row for options, dropdowns, and buttons */}
                    <Form.Item>
                      <Row align="middle" justify="space-between">
                        {/* Left side: Options label and dropdowns */}
                        <Col>
                          <Row gutter={16} align="middle">
                            {/* Options label */}
                            <Col>
                              <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Options:</span>
                            </Col>

                            {/* Dropdown for lengthMenu */}
                            <Col>
                              <Dropdown overlay={lengthMenu} placement="bottomLeft">
                                <Button style={{ width: '150px' }}>{messageVersion}</Button>
                              </Dropdown>
                            </Col>

                            {/* Dropdown for messageTypeMenu */}
                            <Col>
                              <Dropdown overlay={messageTypeMenu} placement="bottomLeft">
                                <Button style={{ width: '150px' }}>{followUpType}</Button>
                              </Dropdown>
                            </Col>

                            {/* Dropdown for messageTypeMenu */}
                            <Col>
                              <Dropdown overlay={emailToneMenu} placement="bottomLeft">
                                <Button style={{ width: '150px' }}>{emailTone}</Button>
                              </Dropdown>
                            </Col>
                          </Row>
                        </Col>

                        {/* Right side: Buttons */}
                        <Col>
                          <Row gutter={10}>
                            {/* Example follow-ups button */}
                            <Col>
                              <Popover
                                content={renderFollowUpMessages(selectedThread.email_template.follow_up_messages)}
                                title="Follow-up Messages"
                                trigger="click"
                              >
                                <Button>Example follow-ups</Button>
                              </Popover>
                            </Col>

                            {/* Approve & Send button */}
                            <Col>
                              <Button type="primary" onClick={handleApprove} disabled={isLoading}>
                                Approve & Send
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form.Item>                    

                    {/* ReactQuill editor for the message */}
                    <div
                    style={{
                      height: 'calc(100vh - 360px)', // Dynamic height based on viewport
                      overflowY: 'auto',
                      paddingBottom: '0px'
                    }}
                  >                    
                    <ReactQuill theme="snow" value={convertNewlinesToHtml(messageText)} onChange={setMessageText} />
                    </div>                    
                  </Form>
                </Col>
              </Row>              
            </>
          ) : (
            <div>Select a thread to view details</div>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default PendingInbox;

