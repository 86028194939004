import React, { useEffect, useState } from 'react';
import { Popover, Button, Card, Statistic } from 'antd';
import { MonitorOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';
import Notification from '../Notification/NotificationMessage';

const AnalyzeURLPopover = ({ onAnalyze, newURLCount }) => {
  const navigate = useNavigate();
  const { qualificationCredit, fetchUserProfile } = useProfilePageHook();
  const [visible, setVisible] = useState(false);

  const handleAnalyze = async () => {
    if (qualificationCredit >= newURLCount) {
      await onAnalyze();
      await fetchUserProfile();
    } else {
      Notification.error('Not enough qualification credits');
    }
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
    if (newVisible) {
      fetchUserProfile();
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  return (
    <Popover
      content={
        <Card title="Qualify Leads" bordered={false} style={{ width: 300 }}>
          <p style={{marginTop:0, marginBottom:15}}>
            DealightAI follows instructions in your sales agent setting. To change how DealightAI conducts real-time research, go to the 
            <Button type="link" onClick={() => navigate('/leads/campaign_setup')} style={{ padding: 0, marginLeft: '2px', height: 'auto' }}>setup page</Button>.
          </p>
          <Statistic title="Credits Left:" value={qualificationCredit} />

          <Button type="primary" onClick={handleAnalyze} style={{ width: '100%', marginTop: '0px' }} disabled={newURLCount === 0 || qualificationCredit < newURLCount}>
            Run and research new URLs ({newURLCount})
          </Button>
        </Card>
      }
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <Button type="primary" icon={<MonitorOutlined />}>Qualify Leads</Button>
    </Popover>
  );
};

export default AnalyzeURLPopover;



