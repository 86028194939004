import React, { useEffect } from 'react';
import { Menu, Dropdown, Avatar, Typography, message, Space, Button } from 'antd';
import { UserOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { useAuth } from '../Login/AuthContext';
import { useNavigate } from 'react-router-dom';

import useProfilePageHook from './ProfilePageHook';

const { Text } = Typography;

const UserProfileDropdown = () => {
  const { 
    isLoggedIn, setIsLoggedIn, 
    showLoginModal, setShowLoginModal, 
    username, setUsername, 
    password, setPassword, 
    token, setToken 
  } = useAuth();
  const navigate = useNavigate();
  const { profile, isLoading, fetchUserProfile } = useProfilePageHook();
  const handleLogout = () => {
    localStorage.removeItem('jwtToken'); // Remove JWT token from local storage
    setIsLoggedIn(false);
    setToken(null);
    message.success('Logged out successfully!'); // Show a success message
    navigate('/');
  };


  useEffect(() => {
    fetchUserProfile();
  }, []);


  const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout} icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );


  return (
    <Space>
      <Avatar size="small" icon={<UserOutlined />} />
      <div style={{paddingTop: '3px'}}>
      <Text strong style={{ color: '#FFFFFF' }}>{profile.email}</Text>
      </div>    
      <div style={{paddingTop: '4px'}}>
      <Dropdown overlay={menu} trigger={['click']}>
        <SettingOutlined style={{ color: '#FFFFFF', cursor: 'pointer' }} />
      </Dropdown>
      </div>  
    </Space>
  );
};

export default UserProfileDropdown;

