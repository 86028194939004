import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Table, Space, Tag, Image, Select, Collapse, message, Modal, Row, Col, Divider, Typography, Tooltip } from 'antd';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import CompanyDetailsPage from './CompanyDetailsPage';
import CrmExportModal from './CrmExportModal';
import CompanyDetailsDrawer from './CompanyDetailsDrawer';

const { Option } = Select;
const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const MyLeadsPage = () => {
  const { getSystemSessions, getSystemTasks, exportCSV, exportHubSpot, exportSalesforce, systemTasks, pollTime, getIntegrationStatus } = useDocumentPageHook();
  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [crmModalVisible, setCrmModalVisible] = useState(false);
  const [integrationStatus, setIntegrationStatus] = useState({});
  const [isExporting, setIsExporting] = useState(false);


  const toggleDrawer = (record) => {
    if (record == selectedLead) {
      if (drawerVisible) {
        setDrawerVisible(false);
      } else{
        setDrawerVisible(true);
      }
    } else {
      setDrawerVisible(true);
    }  
  };

  const onDrawerClose = () => {
    setDrawerVisible(false);
  };  

  const fetchSystemSessions = useCallback(async () => {
    const data = await getSystemSessions();
    if (data) {
      setTasks(data);
      if (data.length > 0) {
        const newestTask = data.reduce((a, b) => new Date(a.upload_date) > new Date(b.upload_date) ? a : b);
        setSelectedTask(newestTask.session_id);
        fetchSystemTasks(newestTask.session_id);
      }
    }
  }, []);


  const fetchSystemTasks = useCallback(async (taskId) => {
    const data = await getSystemTasks(taskId);
    if (data) {
        console.log(data)
        setSortedData(data?.company_details);
    }
  }, [getSystemTasks]); // Using getSystemTasks as the dependency


  useEffect(() => {
    fetchSystemSessions(); 
  }, []);

  useEffect(() => {
    if (selectedTask != null && systemTasks.session_id == selectedTask) {
      setSortedData(systemTasks?.company_details);      
    }
  }, [pollTime]); 
  
  useEffect(() => {
    async function fetchIntegrationStatus() {
      const status = await getIntegrationStatus();
      setIntegrationStatus(status);
    }
    fetchIntegrationStatus();
  }, []);  

  const handleTaskChange = (value) => {
    setSelectedTask(value);
    fetchSystemTasks(value);
  };

  const handleConfirmExport = async () => {
    setIsExporting(true);
    try {
      setCrmModalVisible(false);      
      if (integrationStatus?.hubspot) {
        await exportHubSpot(selectedTask);
      } else if (integrationStatus?.salesforce) {
        await exportHubSpot(selectedTask);
      }
    } catch (error) {
      console.error('Error exporting to CRM:', error);
    } finally {
      setIsExporting(false);
    }
  };  
  // Define a custom sorting function
  const relevanceSort = (a, b) => {
    const tagOrder = {
      'HIGHLY_RELEVANT': 1,
      'RELEVANT': 2,
      'IRRELEVANT': 3
    };

    const tagA = tagOrder[a.company_data.relevance.tag] || 0;
    const tagB = tagOrder[b.company_data.relevance.tag] || 0;

    if (tagA === tagB) {
      return b.company_data.relevance.relevance - a.company_data.relevance.relevance;
    }

    return tagA - tagB;
  };  

  // const columns = [
  //   {
  //     title: 'Relevance',
  //     dataIndex: ['company_data', 'relevance'],
  //     key: 'relevance',
  //     sorter: relevanceSort,
  //     render: (relevance) => {
  //       let color = relevance?.tag === 'IRRELEVANT' ? 'red' : 'green';
  //       return (
  //         <Tag color={color}>{relevance?.tag}</Tag>
  //       );
  //     },
  //     width: 100
  //   },
  //   {
  //     title: 'Name',
  //     key: 'name',
  //     render: (_, record) => (
  //       <Space>
  //         {record.company_data?.contact_people[0]?.name}
  //       </Space>
  //     ),
  //     width: 150
  //   }, 
  //   {
  //     title: 'Title',
  //     key: 'title',
  //     render: (_, record) => (
  //       <Space>
  //         {record.company_data?.contact_people[0]?.title || 'NA'}
  //       </Space>
  //     ),
  //     width: 200
  //   },       
  //   {
  //     title: 'Company',
  //     key: 'company_name',
  //     render: (_, record) => (
  //       <Space>
  //         <Image
  //           width={40}
  //           height={40}
  //           src={record.company_data?.company_logo_url || '/person_icon.png'}
  //           style={{ borderRadius: '50%' }}
  //           alt="logo"
  //         />
  //         {record.company_data?.company_name}
  //       </Space>
  //     ),
  //     width: 200
  //   },
  //   {
  //     title: 'Industry',
  //     dataIndex: ['company_data', 'company_industry'],
  //     key: 'industry',
  //     render: industry => (
  //       <Tag color="blue">{industry}</Tag>
  //     ),
  //     width: 80
  //   },  
  //   {
  //     title: 'Processed At',
  //     dataIndex: 'processed_at',
  //     key: 'processed_at',
  //     sorter: (a, b) => new Date(a.processed_at) - new Date(b.processed_at),
  //     render: processed_at => new Date(processed_at).toLocaleDateString('en-US'),
  //     width: 150
  //   },
  // ];

  const truncateLocation = (location, maxLength = 30) => {
    if (location.length > maxLength) {
      return `${location.slice(0, maxLength)}...`;
    }
    return location;
  };  

  const columns = [      
    {
      title: 'Company',
      key: 'company_name',
      render: (_, record) => (
        <Space>
          <Image
            width={40}
            height={40}
            src={record.company_data?.company_logo ? `data:image/jpeg;base64,${record.company_data.company_logo}`: (record.company_data?.company_logo_url || '/person_icon.png')}
            style={{ borderRadius: '50%' }}
            alt="logo"
          />
        <a 
          style={{ color: '#1890ff', textDecoration: 'underline', cursor: 'pointer' }}
        >
          {record.company_data?.company_name}
        </a>
        </Space>
      ),
      width: 200
    },
    {
      title: 'Employees',
      key: 'company_size',
      render: (_, record) => (
        <Space>
          {record.company_data?.company_size || '0'}
        </Space>
      ),
      width: 200
    },  
    {
      title: 'Location',
      key: 'location',
      render: (_, record) => {
        const location = record.company_data?.location || 'NA';
        const truncatedLocation = truncateLocation(location, 15); // Truncate to 30 characters (adjust as needed)
    
        return (
          <Space>
            <Tooltip title={location}>
              {truncatedLocation}
            </Tooltip>
          </Space>
        );
      },
      
    },      
    {
      title: 'Industry',
      dataIndex: ['company_data', 'company_industry'],
      key: 'industry',
      render: industry => (
        <Tag color="blue">{industry}</Tag>
      ),
      width: 80
    },  
    {
      title: 'Processed At',
      dataIndex: 'processed_at',
      key: 'processed_at',
      sorter: (a, b) => new Date(a.processed_at) - new Date(b.processed_at),
      render: processed_at => new Date(processed_at).toLocaleDateString('en-US'),
      width: 150
    },
  ];  

  return (
    <div style={{ 
      width: '100%',
      margin: '0 auto', 
      padding: '12px 16px', 
      backgroundColor: '#fff', 
      borderRadius: '16px', 
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '0',
      overflow:'auto'
    }}>
      <Row gutter={16} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
        <Col>
          <Select 
            value={selectedTask} 
            onChange={handleTaskChange} 
            style={{ width: 200 }} 
            placeholder="Select Task"
          >
            {tasks?.map(task => (
              <Option key={task.session_id} value={task.session_id}>
                {task.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
          <Button 
            onClick={() => setCrmModalVisible(true)} 
            type="primary" 
            loading={isExporting}
          >
            Export to CRM
          </Button>           
          <Button 
            onClick={() => exportCSV(selectedTask)} 
            type="primary"
          >
            Export CSV
          </Button>
        </Col>
      </Row>
      <Row gutter={16} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col span={24} style={{ flex: 2, padding: '24px', borderRadius: '8px', border: '1px solid #d9d9d9', backgroundColor: '#fff' }}>
          <Table
            columns={columns}
            dataSource={sortedData}
            rowKey="url_id"
            pagination={{ pageSize: 10 }}
            style={{ cursor: 'pointer' }}
            onRow={(record) => ({
              onClick: () => {
                setSelectedLead(record);
                toggleDrawer(record);
              }
            })}
          />
        </Col>
      </Row>
      <CompanyDetailsDrawer visible={drawerVisible} onClose={onDrawerClose} record={selectedLead} fullData={sortedData} />
      <CrmExportModal
        visible={crmModalVisible}
        onClose={() => setCrmModalVisible(false)}
        onConfirm={handleConfirmExport}
        integrationStatus={integrationStatus}
        numData={sortedData.length}
      />      
    </div>
  );
};

export default MyLeadsPage;
