import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, message, Table, Tooltip, Typography, Tag, Space, Popconfirm, Statistic } from 'antd';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';

import DeleteWithModal from './DeleteModal';
import { InfoCircleOutlined } from '@ant-design/icons';

import './LeadsPage.css';  // Assume we are using a CSS file for styles

const { Text } = Typography;

const TasksPage = () => {
  const { sessions, setSessions, getSystemSessions, cleanupSessions } = useDocumentPageHook();
  const { generationCredit, fetchUserProfile } = useProfilePageHook();

  const navigate = useNavigate();

  const fetchSessions = async () => {
    await fetchUserProfile();
    const data = await getSystemSessions();
    if (data) {
      setSessions(data);
    } else {
      message.error('Failed to load sessions. Try again later');
    }
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  const getTagColor = (state) => {
    switch (state) {
      case 'RUNNING':
      case 'PAUSED':
        return 'yellow';
      case 'COMPLETED':
        return 'green';
      case 'DEFAULT':
      case 'FAILED':
      case 'ABORTED':
        return 'red';
      default:
        return 'blue';
    }
  };

  const handleDelete = async (session_id) => {
    try {
      await cleanupSessions(session_id);  // Call the cleanup session function
      message.success('Session deleted successfully');
      fetchSessions();  // Refresh the sessions list
    } catch (error) {
      message.error('Failed to delete session. Please try again later.');
    }
  };

  const columns = [
    {
      title: 'Session Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Daily Progress',
      dataIndex: 'url_details',
      key: 'url_count',
      render: (_, record) => {
        const percentage = (record.analyzed_urls / record.total_urls_to_process) * 100;
        const progress = record.daily_generation_quota - record.daily_relevant_research_quota;

        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Text>
            {progress} / {record.daily_generation_quota}
          </Text>
        </div>
        );
      },
      width: 140,
    },
    {
      title: 'Automation',
      dataIndex: 'is_automated_session',
      key: 'is_automated_session',
      render: (_, record) => (
        <span>
          {record.is_automated_session ? (
            <Tag color="green">Enabled</Tag>
          ) : (
            <Tag color="red">Disabled</Tag>
          )}{' '}
          <Tooltip title="If enabled, this session will retrigger at 12:00AM PST daily" >
            <InfoCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)', marginLeft:0 }} />
          </Tooltip>
        </span>
      ),
      width: 150,
    }, 
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (_, record) => (
        <Tag color={getTagColor(record.session_state)}>
          {record.session_state}
        </Tag>
      ),
    },    
    {
      title: 'Date',
      dataIndex: 'upload_date',
      key: 'upload_date',
      render: date => new Date(date).toLocaleDateString('en-GB'),  // Format to 'dd/mm/yyyy'
    },    
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => navigate(`/campaign/tasks/${record.session_id}`, { state: { session_id: record.session_id } })}>
            Details
          </Button>
          <DeleteWithModal record={record} handleDelete={handleDelete} />
        </Space>
      ),
    },
  ];

  return (
    <div className="my-leads-page">
      <div className="upload-section">
        <div className="upload-content" style={{ width: '100%' }}>
        {/* Left side with icon and text */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src="/upload_icon.png" alt="Upload icon" className="upload-icon" />
            <div className="upload-text">
              <h3>Initiate Lead Analysis</h3>
              <p>Go to setup page to initiate the task. The results will be shown here.</p>
            </div>
          </div>

          {/* Right side with statistics */}
          <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}> 
            <Statistic 
              title={
                <span>
                  Generation Credit 
                  <Tooltip title="When generation credit becomes 0, daily automation for lead generation will stop.">
                    <InfoCircleOutlined style={{ marginLeft: 8 }} />
                  </Tooltip>
                </span>
              } 
              value={generationCredit} 
            />
          </div>
        </div>
      </div>

      <div className="gen-content-section">
        <Table
          columns={columns}
          dataSource={sessions}
          rowKey="session_id"
          pagination={{ pageSize: 50 }}
        />
      </div>
    </div>
  );
};

export default TasksPage;
