import axios from 'axios';
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    setIsLoggedIn(false);
    setToken(null);
    setUsername('');
    setPassword('');
    setShowLoginModal(true);
  };

  // Function to validate the token
  const validateToken = async () => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      setIsLoggedIn(true);
      setToken(token);
    }
    return true
  };

  const value = {
    isLoggedIn,
    setIsLoggedIn,
    showLoginModal,
    setShowLoginModal,
    username,
    setUsername,
    password,
    setPassword,
    token,
    setToken,
  };

  return (
    <AuthContext.Provider value={{...value, validateToken, handleLogout}}>
      {children}
    </AuthContext.Provider>
  );
};

export const getToken = () => localStorage.getItem('jwtToken');
