import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../Login/AuthContext';
import Notification from '../Notification/NotificationMessage';

const usePricingPageHook = () => {
    const location = useLocation();
    const currentUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;    
    const navigate = useNavigate();


    const getProducts = async () => {
        const token = getToken();
        if (!token) {
            Notification.error('User is not authenticated', 'Please reload the page or logout and try again.');
            // Optionally, redirect to login
            return;
        }
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}subscribe/products/`, {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            });
            return response.data.product; // Assuming the backend sends data in { product: [...] } format
        } catch (error) {
            console.error("Error fetching products:", error);
            Notification.error('Error fetching products');
            // Handle error based on status code or error message
        }
    };

    const getSubscriptionPage = async () => {
        const token = getToken();
        const return_url = `${currentUrl}`; // Corrected variable name
    
        if (!token) {
            Notification.error('User is not authenticated', 'Please reload the page or logout and try again.');
            // Optionally, redirect to login
            return;
        }
    
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}subscribe/billing_management_page/`, 
                {
                    params: { return_url }, // Using params for GET request
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                }
            );            
            window.location.href = response.data.billing_management_url
        } catch (error) {
            console.error("Error fetching products:", error);
            Notification.error('Error fetching management URL');
            // Handle error based on status code or error message
        }        
    }
       
    

    const createCheckoutSession = async (priceId, document_id) => {
        const token = getToken();
        if (!token) {
            Notification.error('User is not authenticated', 'Please reload the page or logout and try again.');
            return;
        }
    
        const successUrl = `${currentUrl}?status=success`; // Modify as needed
        const cancelUrl = `${currentUrl}`;
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}subscribe/create_checkout_session/`, 
                {
                    priceId,
                    document_id,
                    successUrl, 
                    cancelUrl
                },
                {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                }
            );
            // Navigate to Stripe Checkout using the URL from the response
            window.location.href = response.data.sessionURI;
        } catch (error) {
            console.error("Error creating checkout session:", error);
            Notification.error('Error creating checkout session');
            // Handle error based on status code or error message
        }
    };
            
    return {
        getProducts,
        createCheckoutSession,
        getSubscriptionPage,
    };
};

export default usePricingPageHook;