import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Card, List, Image, Typography, Tag, Divider, Tooltip, Button, Tabs, Space, Table } from 'antd';
import { LinkedinOutlined, InfoCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';
import DetailsDrawer from './PeopleDetailsDrawer';


const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

const FullDetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [fullLead, setFullLead] = useState(location.state?.lead);
  const [lead, setLead] = useState(location.state?.lead.company_data);
  const [fullData, setFullData] = useState(location.state?.fullData);
  const [selectedLead, setSelectedLead] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
    const contactPeople = lead?.contact_people || [];
  const currentIndex = fullData.findIndex(item => item.url_id === fullLead.url_id);

  useEffect(() => {
    setFullLead(location.state?.lead);
    setLead(location.state?.lead.company_data);
    setFullData(location.state?.fullData);
  }, [location.state]);  
  if (!lead) return <div>No company data available.</div>;


  const goToLead = (offset) => {
    const newIndex = currentIndex + offset;
    if (newIndex >= 0 && newIndex < fullData.length) {
      const newLeadData = fullData[newIndex];
      navigate(`/campaign/leads/${newLeadData.url_id}`, {
        state: { lead: newLeadData, fullData: fullData },
        replace: true,
        key: new Date().toString()  // Change key to force re-render
      });
    }
  }; 

  const toggleDrawer = (record) => {
    console.log(record)
    if (record == selectedLead) {
      if (drawerVisible) {
        setDrawerVisible(false);
      } else{
        setDrawerVisible(true);
      }
    } else {
      setDrawerVisible(true);
    }  
  };

  const onDrawerClose = () => {
    setDrawerVisible(false);
  };   
  

  const truncateContent = (content, maxLength = 35) => {
    if (!content) return '';
    return content.length > maxLength ? content.slice(0, maxLength) + '...' : content;
  };    

  const renderInsights = (insights, tag) => {
    const filteredQuestions = insights?.filter((question) => (question.tag || 'PREFERRED') === tag);
  
    return filteredQuestions?.map((question, index) => (
      <div key={index} style={{ marginBottom: 16 }}>
        <Text>Q: {question.question}</Text>
        <div>
          <Text strong>
            A: {Array.isArray(question.answer) 
              ? question.answer.join('\n') // Join array elements with new lines
              : question.answer}. 
          </Text>
          <Tooltip
            title={
              <div dangerouslySetInnerHTML={{ __html: question?.additional_insights?.join('<br /><br>') }} />
            }
          >
            <Button shape="circle" icon={<InfoCircleOutlined />} style={{ marginLeft: 8 }} />
          </Tooltip>
        </div>
        <div>
          <Text>Note: {question.short_reply}</Text>             
        </div>
        {question.reference && question.reference.length > 0 && (
          <div style={{ marginTop: 8 }}>
            {question.reference.filter(ref => ref.startsWith('http') || ref.startsWith('https')).map((ref, idx) => (
              <Tag color="blue" key={idx}>
                <a href={ref} target="_blank" rel="noopener noreferrer">{truncateContent(ref)}</a>
              </Tag>
            ))}
          </div>
        )}
        <Divider />
      </div>
    ));
  };

  const columns = [
    {
      title: 'Contact',
      dataIndex: 'logo_url',
      key: 'logo_url',
      render: (text, record) => (
        <Space>
          <Image
            width={35}
            height={35}
            src={record.logo_url || '/person_icon.png'}
            style={{ borderRadius: '50%' }}
            alt="profile"
          />
        <a 
          style={{ color: '#1890ff', textDecoration: 'underline', cursor: 'pointer' }}
        >
          {record.name}
        </a>
        </Space>
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
        title: 'Email',
        dataIndex: 'email_address',
        key: 'email_address'
      },    
    {
      title: 'LinkedIn',
      dataIndex: 'url',
      key: 'url',
      render: url => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <LinkedinOutlined style={{ fontSize: '20px', color: '#0077b5' }} />
        </a>
      )
    }
  ];    
  
  const renderSignals = (signals, color) => {
    return (
      <ul>
        {signals?.map((signal, index) => (
          <li key={index} style={{ color: color }}>
            {signal}
          </li>
        ))}
      </ul>
    );
  };  

  const InsightsSection = ({ title, tag, companyInsights, peopleInsights }) => {
    // Combine both company and people insights under the same tag
    //const combinedInsights = [...(companyInsights?.key_questions || []), ...(peopleInsights?.key_questions || [])];
    const combinedInsights = [...(companyInsights?.key_questions || [])];

    
    const renderedInsights = renderInsights(combinedInsights, tag)
    if (renderedInsights.length === 0) {
      return null;
    }    
  
    return (
      <>
        <Title level={4}>{title}</Title>
        <Divider solid style={{ marginBottom: 10, marginLeft: 0, marginTop: 0, marginRight: 0, borderColor: '#000000' }} />
        {renderedInsights}
      </>
    );
  };  

  const logo = (
    <Row align="middle" justify="space-between" style={{ margin: 0, padding: 0 }}>
      <Col>
        <Image
          width={35}
          height={35}
          src={lead.company_logo ? `data:image/jpeg;base64,${lead.company_logo}`: (lead?.company_logo_url || '/person_icon.png')}
          style={{ borderRadius: '50%' }}
          alt="logo"
        />
        <Title level={5} style={{ margin: '0 0 0 10px', display: 'inline-block' }}>
          {lead.company_name || 'N/A'}
        </Title>
      </Col>
    </Row>    
  );

  // Renders individual buying intent items
  const renderBuyingIntent = () => {
    return lead?.buying_intents?.buying_intents?.map((intent, index) => (
      <Card key={index} title={intent.title} style={{ marginBottom: 16 }}>
        <p>{intent.content}</p>
        {intent.reference.length > 0 && (
          <div>
            <strong>References:</strong>
            <List
              dataSource={intent.reference}
              renderItem={item => (
                <List.Item>
                  <a href={item} target="_blank" rel="noopener noreferrer">{item}</a>
                </List.Item>
              )}
            />
          </div>
        )}
        <Tag color={getRelevanceColor(intent.relevance)}>{intent.relevance}</Tag>
      </Card>
    ));
  };

  // Optionally, set color based on relevance
  const getRelevanceColor = (relevance) => {
    switch(relevance) {
      case 'HIGHLY_RELEVANT':
        return 'green';
      case 'RELEVANT':
        return 'blue';
      case 'NEUTRAL':
        return 'grey';
      default:
        return 'default';
    }
  };  
  function isEmpty(obj) {
    return (
      obj === null || 
      obj === undefined || 
      (Object.keys(obj).length === 0 && obj.constructor === Object) || 
      (Array.isArray(obj) && obj.length === 0)
    );
  }  

  return (
    <div style={{ padding: '10px', overflow: 'hidden', width:'100%' }}>
      <Row gutter={4} justify="end">
        <Col>
          <Button onClick={() => goToLead(-1)} disabled={currentIndex === 0} style={{ marginRight: '0px' }}>
            Previous Profile
          </Button>
        </Col>
        <Col>
          <Button onClick={() => goToLead(1)} disabled={currentIndex === fullData.length - 1} style={{marginBottom:'10px'}}>
            Next Profile
          </Button>
        </Col>
      </Row>
      <Row gutter={16} style={{height:'calc(100vh - 175px)'}}>
        {/* Left Column for Company Info */}
        <Col span={8} style={{ overflowY: 'auto', height: '100%', paddingRight: '20px' }}>
          <div style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '16px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            {logo}
            <Divider />
            <Paragraph><strong>Domain:</strong> <div></div><a href={lead.company_website_url} target="_blank" rel="noopener noreferrer">{lead.company_website_url}</a></Paragraph>
            <Paragraph><strong>Linkedin:</strong><div></div> <a href={lead.company_linkedin_url} target="_blank" rel="noopener noreferrer">{lead.company_linkedin_url}</a></Paragraph>

            <Paragraph><strong>Location:</strong> <div></div>{lead.location || 'NA'}</Paragraph>
            <Paragraph><strong>Industry:</strong> <div></div>{lead.company_industry}</Paragraph>
            <Paragraph><strong>Size:</strong> <div></div><Space>{lead.company_size} employees</Space></Paragraph>
            <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
              <strong>Description:</strong>
              <Space>{lead.company_description}</Space>
            </Paragraph>
            <div>
                  <InsightsSection title="Required Insights" tag="REQUIRED" companyInsights={lead.company_insights} peopleInsights={lead.people_insights} />
                  <InsightsSection title="Preferred Insights" tag="PREFERRED" companyInsights={lead.company_insights} peopleInsights={lead.people_insights} />

                  {!isEmpty(lead.custom_insights) && (
                    <>
                      <Title level={4}>Signals</Title>
                      <Divider />
                      <strong>Positive Signals</strong>
                      <div>{renderSignals(lead.custom_insights?.positive_signals, 'black')}</div>
                      <Divider />
                      <strong>Negative Signals</strong>
                      <div>{renderSignals(lead.custom_insights?.negative_signals, 'black')}</div>
                    </>
                  )}
                </div>            
          </div>
        </Col>

        {/* Right Column for Reports and Insights */}
        <Col span={16} style={{ overflowY: 'auto', height: '100%' }}>
          <div style={{width: '100%', backgroundColor: '#fff', padding: '20px', borderRadius: '16px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="People" key="1">
                <Table 
                dataSource={contactPeople}
                columns={columns}
                rowKey="url_id"
                pagination={{ pageSize: 10 }}
                style={{ cursor: 'pointer' }}
                onRow={(record) => ({
                  onClick: () => {
                    setSelectedLead(record);
                    toggleDrawer(record);
                  }
                })}                
                />

              </TabPane>
              <TabPane tab="Buying Intent" key="2">
                {renderBuyingIntent()}
              </TabPane>              
            </Tabs>
          </div>
        </Col>
      </Row>
      <DetailsDrawer visible={drawerVisible} onClose={onDrawerClose} company={lead} contactPerson={selectedLead} />

    </div>
    
  );
};

export default FullDetailsPage;

