import React, { useState, useEffect } from 'react';
import { Form, Button, Spin, message, Typography, Switch, Card, Select, Input, Row, Col } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import GoogleAuthButton from './GmailIntegration';
import HubspotAuthButton from './HubspotIntegration';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';

const { Title } = Typography;
const { TextArea } = Input;

const IntegrationPage = () => {
  const { getEmailContext, getIntegrationStatus } = useDocumentPageHook();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [integrationStatus, setIntegrationStatus] = useState({ gmail: false });

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const status = await getIntegrationStatus();
        if (status) {
          setIntegrationStatus(status);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const updateIntegrationStatus = async () => {
    try {
      const status = await getIntegrationStatus();
      setIntegrationStatus(status);
      return status
    } catch (error) {
      console.error('Failed to update integration status:', error);
      return null;
    }
  };

  return (
    <div className="preference-page">
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <div style={{
          minWidth: '792px',
          margin: '0 auto',
          padding: '22px 66px',
          backgroundColor: '#fff',
          borderRadius: '16px',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '0'
        }}>
          <Title level={3}>Integration</Title>
          {/* <GoogleAuthButton updateIntegrationStatus={updateIntegrationStatus} integrationStatus={integrationStatus.gmail} /> */}          
          <HubspotAuthButton updateIntegrationStatus={updateIntegrationStatus} integrationStatus={integrationStatus.hubspot} />
        </div>
      )}
    </div>
  );
};

export default IntegrationPage;
