import React, { useState, useEffect } from 'react';
import { Typography, Input, Button, Form, Modal, notification } from 'antd';
import { getToken } from '../Login/AuthContext';
import axios from 'axios';

const { Title, Paragraph } = Typography;

// ContactUs component is modified to be usable both as a standalone page and in a modal.
const ContactUs = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
      // Reset message input when the form is closed and reopened
      if (!visible) {
          setMessage('');
          form.resetFields();
      }
  }, [visible, form]);

  const onFinish = async (values) => {
      setIsSubmitting(true);
      try {
          const token = getToken();
          if (!token) {
              notification.error({
                  message: 'User is not authenticated',
                  description: 'Please reload the page or logout and try again.',
              });
              setIsSubmitting(false);
              return;
          }

          const response = await axios.post(`${process.env.REACT_APP_API_URL}send_message/`, values, {
              headers: {
                  'Authorization': `Token ${token}`,
              }
          });
          notification.success({
              message: 'Message Sent',
              description: response.data.message,
          });
          onClose(); // Close the modal on success
      } catch (error) {
          notification.error({
              message: 'Error',
              description: error.response?.data?.message || 'There was an error sending your message.',
          });
      } finally {
          setIsSubmitting(false);
      }
  };

  return (
      <Modal title="Contact Us" visible={visible} onCancel={onClose} footer={null}>
          <Paragraph>
            Have any question? Let us know!
          </Paragraph>
          <Form form={form} onFinish={onFinish}>
              <Form.Item
                  name="message"
                  rules={[{ required: true, message: 'Please input your message!' }]}
              >
                  <Input.TextArea
                      placeholder="Your message"
                      rows={4}
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                  />
              </Form.Item>
              <Form.Item
                  wrapperCol={{ 
                      offset: 16, span: 8 
                  }}
              >
                  <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isSubmitting || !message.trim()}
                      style={{ float: 'right' }} // Align the button to the right
                  >
                      {isSubmitting ? 'Sending...' : 'Send Message'}
                  </Button>
              </Form.Item>
          </Form>
          <Paragraph>
            <strong>Or directly email:</strong> help@dealight.ai
          </Paragraph>
      </Modal>
  );
};

export default ContactUs;