import React, { useState, useEffect } from 'react';
import { Form, Button, Spin, message, Typography, Switch, Card, Select, Input, Row, Col, Tooltip, Divider, Descriptions } from 'antd';
import { PlusOutlined, MinusCircleOutlined, MailOutlined } from '@ant-design/icons';
import GoogleAuthButton from './GmailIntegration';
import NylasAuthButton from './NylasAuthButton';
import { useNavigate } from 'react-router-dom';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';

const { Title } = Typography;
const { TextArea } = Input;

const EmailSetupPage = () => {
  const { getEmailContext, createOrUpdateEmailContext, getIntegrationStatus } = useDocumentPageHook();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [integrationStatus, setIntegrationStatus] = useState({ gmail: false, nylas: false });
  const [emailAutomation, setEmailAutomation] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const emailContext = await getEmailContext();
        console.log(emailContext)
        if (emailContext) {
          form.setFieldsValue({
            ...emailContext,
          });
          setEmailAutomation(emailContext.emailAutomation);
          setInitialLoad(false);
        }
        const status = await getIntegrationStatus();
        if (status) {
          setIntegrationStatus(status);
        }        
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [form]);

  const handleSaveClick = () => {
    form.validateFields().then(handleSubmit).catch(handleValidationError);
  };

  const handleSubmit = async (values) => {
    const formData = {
      emailAutomation,
      automationLevel: values.automationLevel,
      defaultVersion: values.defaultVersion,
      defaultTone: values.defaultTone,
      followUps: values.followUps,
    };
    try {
      await createOrUpdateEmailContext(formData);
    } catch (error) {
      console.error('Failed to save email setup:', error);
    }
  };

  const handleValidationError = (errorInfo) => {
    if (errorInfo.errorFields && errorInfo.errorFields.length > 0) {
      const errorMessage = errorInfo.errorFields.map(field => `${field.name[0]}: ${field.errors[0]}`).join(', ');
      message.error(errorMessage);
    } else {
      console.log(errorInfo);
      message.error('Form submission failed');
    }
  };

  const ordinalSuffix = (i) => {
    const j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return `${i}st`;
    }
    if (j === 2 && k !== 12) {
      return `${i}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  };
  const updateIntegrationStatus = async () => {
    try {
      const status = await getIntegrationStatus();
      setIntegrationStatus(status);
      return status
    } catch (error) {
      console.error('Failed to update integration status:', error);
      return null;
    }
  };  

  return (
    <div className="preference-page">
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <div style={{
          minWidth: '792px',
          margin: '0 auto',
          padding: '22px 66px',
          backgroundColor: '#fff',
          borderRadius: '16px',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '0'
        }}>
          <Title level={3}>Email Setup</Title>
          <NylasAuthButton updateIntegrationStatus={updateIntegrationStatus} integrationStatus={integrationStatus} />          
          <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
              >

          <Row justify="end" style={{ marginTop: 24 }}>
              <Button type="primary" onClick={handleSaveClick}>
                Save
              </Button>
            </Row>                          
          <Row gutter={24}>
            {/* Left Part: Form Items */}
            <Col span={9}>
              <Title level={3}>Settings</Title>

                <Form.Item
                  name="automationLevel"
                  label="Automation Level"
                  initialValue="need_approval"
                  rules={[{ required: true, message: 'Please select automation level' }]}
                >
                  <Select placeholder="Select automation level">
                    <Select.Option value="automatically">Send automatically</Select.Option>
                    <Select.Option value="need_approval">Need approval</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="defaultVersion"
                  label={
                    <Tooltip title="This version will be used as default for email sequences">
                      Default Version
                    </Tooltip>
                  }
                  rules={[{ required: true, message: 'Please select default version' }]}
                >
                  <Select placeholder="Select default version">
                    <Select.Option value="version1">Version 1</Select.Option>
                    <Select.Option value="version2">Version 2</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="defaultTone"
                  label={
                    <Tooltip title="The tone used by default in email communication">
                      Default Tone
                    </Tooltip>
                  }
                  rules={[{ required: true, message: 'Please select default tone' }]}
                >
                  <Select placeholder="Select default tone">
                    <Select.Option value="friendly">Friendly</Select.Option>
                    <Select.Option value="formal">Formal</Select.Option>
                    <Select.Option value="informal">Informal</Select.Option>
                  </Select>
                </Form.Item>    
            </Col>

            <Divider type="vertical" style={{ height: '300px' }} />

           {/* Right Part: Sequences */}
                <Col span={13}>
                <Title level={3}>Sequences</Title>

            <Form.List name="followUps" initialValue={[{ stepType: 'email', days: 0 }]}>
                {(fields, { add, remove }) => (
                <>
                    {fields.map((field, index) => (
                    <Card key={field.key} style={{ marginBottom: 16 }}>
                        <Row gutter={16} align="middle">
                        {/* Icon for each step */}
                        <Col span={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <MailOutlined style={{ fontSize: '24px' }} />
                        </Col>

                        {/* Description for each step */}
                        <Col span={9} style={{ }}>
                            {index === 0 ? (
                            <span style={{ fontSize: '16px' }}>Send Initial Email</span>
                            ) : (
                            <span style={{ fontSize: '16px' }}>Follow up after:</span>
                            )}
                        </Col>

                        {/* Follow-up day selector for follow-ups */}
                        {index !== 0 && (
                            <Col span={6} style={{ margin:0, padding:0 }}>
                            <Form.Item
                                {...field}
                                name={[field.name, 'days']}
                                fieldKey={[field.fieldKey, 'days']}
                                rules={[{ required: true, message: 'Please select wait days' }]}
                            >
                                <Select placeholder="Select days" style={{marginTop:26}}>
                                {[...Array(7).keys()].map(day => (
                                    <Select.Option key={day + 1} value={day + 1}>
                                    {day + 1} day{day + 1 > 1 ? 's' : ''}
                                    </Select.Option>
                                ))}
                                </Select>
                            </Form.Item>
                            </Col>
                        )}

                        {/* Remove button for follow-ups (not for initial email) */}
                        {index > 0 && isEditMode && (
                            <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                            </Col>
                        )}
                        </Row>
                    </Card>
                    ))}

                    {fields.length < 5 && ( // Maximum 7 sequences including initial email
                    <Form.Item>
                        <Button type="dashed" onClick={() => add({ stepType: 'followup', days: 1 })} block icon={<PlusOutlined />}>
                        Add Follow-Up
                        </Button>
                    </Form.Item>
                    )}
                </>
                )}
            </Form.List>
            </Col>                     
          </Row>
          </Form>          
        </div>
      )}
    </div>
  );
};

export default EmailSetupPage;
