import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from '../Login/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useDocument } from './DocumentContext';
import { getToken } from '../Login/AuthContext';
import Notification from '../Notification/NotificationMessage';

const sentNotifications = {};

const useDocumentPageHook = () => {
    const { businessContext, setBusinessContext, sessions, pollTime, setSessions, sessionDetails, setSessionDetails, systemTasks, pollSystemTasks, getSystemTasks} = useDocument();
    const { handleLogout } = useAuth();
    const [isPolling, setIsPolling] = useState(false); // Add polling state    
    const navigate = useNavigate();

    const analyzeURLs = async (uploadedData, urlCount, totalLeads) => {
      const token = getToken();
      if (!token) {
        Notification.error('User is not authenticated', 'Please reload the page or logout and try again.');
        return;
      }
      console.log(uploadedData)
    
      const formData = new FormData();
      formData.append('count', urlCount);
      formData.append('leads_length', totalLeads);
      formData.append('file', JSON.stringify(uploadedData));  // Attach the file for upload
    
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}analyze/`, formData, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'multipart/form-data',
          }
        });
        Notification.complete('URLs are being processed');
        return response.data;
      } catch (error) {
        console.error("Error analyzing URLs:", error);
        if (error.response && error.response.status === 400) {
          Notification.error('Error', 'No URLs provided');
        } else {
          Notification.error('Error analyzing URLs');
        }
      }
    };    

      const initiateSystemTask = async (urlCount) => {
        const token = getToken();
        if (!token) {
          Notification.error('User is not authenticated', 'Please reload the page or logout and try again.');
          return;
        }
        const formData = new FormData();
        formData.append('count', urlCount)        
      
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}analyze_task/`, formData, {
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            }
          });
          Notification.complete('Task initiated');
          return response.data;
        } catch (error) {
          console.error("Error initiating tasks:", error);
          if (error.response && error.response.status === 400) {
            Notification.error('Error', 'Bad Request. You cannot resubmit the campaign. Or campaign maximum exceeded');
          } else {
            Notification.error('Error initiating tasks');
          }
          handleError(error)          
        }
      };

      const exportHubSpot = async (session_id) => {
        const token = getToken();
        if (!token) {
          Notification.error('User is not authenticated', 'Please reload the page or logout and try again.');
          return;
        }
        const formData = new FormData();
        formData.append('session_id', session_id)        
      
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}export/hubspot/`, formData, {
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            }
          });
          Notification.complete('Exported to hubspot successfully');
          return response.data;
        } catch (error) {
          console.error("Error initiating tasks:", error);
          if (error.response && error.response.status === 400) {
            Notification.error('Error', 'No URLs provided');
          } else {
            Notification.error('Error initiating tasks');
          }
        }
      };      

      const getCampaignOverview = async (campaignId) => {
        const token = getToken();
        if (!token) {
          Notification.error('User is not authenticated', 'Please reload the page or logout and try again.');
          return;
        }
      
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}campaign_overview/`, {
            headers: {
              'Authorization': `Token ${token}`,
            },
            params: {
              campaign_id: campaignId
            }
          });
          return response.data;
        } catch (error) {
          console.error("Error fetching campaign overview:", error);
          Notification.error('Error fetching campaign overview');
        }
      };

      const exportCSV = async (sessionId) => {
        const token = getToken();
        if (!token) {
          Notification.error('User is not authenticated', 'Please reload the page or logout and try again.');
          return;
        }
      
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}export_csv/${sessionId}/`, {
            headers: {
              'Authorization': `Token ${token}`,
            },
            responseType: 'blob',  // This is important to handle the CSV file correctly
          });
      
          // Create a URL for the blob and download the file
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `session_${sessionId}_data.csv`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } catch (error) {
          console.error("Error exporting CSV:", error);
          Notification.error('Error exporting CSV');
        }
      };      
  
      const resumeAnalysisTask = async (sessionId, urlCount) => {
        const token = getToken();
        if (!token) {
          Notification.error('User is not authenticated', 'Please reload the page or logout and try again.');
          return;
        }
      
        const formData = new FormData();
        formData.append('session_id', sessionId);
        formData.append('batch_size', urlCount)
      
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}resume_task/`, formData, {
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            }
          });
          Notification.complete('Task resumed');
          return response.data;
        } catch (error) {
          console.error("Error resuming task:", error);
          Notification.error('Error resuming task');
          handleError(error)
        }
      };

      const stopAnalysisTask = async (sessionId) => {
        const token = getToken();
        if (!token) {
          Notification.error('User is not authenticated', 'Please reload the page or logout and try again.');
          return;
        }
      
        const formData = new FormData();
        formData.append('session_id', sessionId);
      
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}stop_task/`, formData, {
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            }
          });
          Notification.complete('Task stopped');
          return response.data;
        } catch (error) {
          console.error("Error stopping task:", error);
          Notification.error('Error stopping task');
        }
      };
      
      

      const createNewSession = async () => {
        const token = getToken();
        if (!token) {
          Notification.error('User is not authenticated', 'Please reload the page or logout and try again.');
          return;
        }
      
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}create_new_session/`, {}, {
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            }
          });
          return response.data.session_id;
        } catch (error) {
          console.error("Error creating new session:", error);
          Notification.error('Error creating new session');
        }
      };

      const approveAndSendInitialMessage = async (emailDetailId, subject, messageText) => {
        const token = getToken(); // Replace with your token retrieval logic
        if (!token) {
          Notification.error('User is not authenticated', 'Please reload the page or logout and try again.');
          return;
        }
      
        const formData = new FormData();
        formData.append('email_detail_id', emailDetailId);
        formData.append('subject', subject);
        formData.append('message_text', messageText);
      
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}approve_message/`, formData, {
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'multipart/form-data',
            }
          });
          Notification.complete('Message approved and sent successfully');
          return response.data;
        } catch (error) {
          console.error("Error approving and sending message:", error);
          Notification.error('Error approving and sending message');
        }
      };
      

      const uploadNewURLs = async (urls) => {
        const token = getToken();
        if (!token) {
          Notification.error('User is not authenticated', 'Please reload the page or logout and try again.');
          return;
        }
      
        const formData = new FormData();
        formData.append('urls', JSON.stringify(urls));
      
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}upload_urls/`, formData, {
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'multipart/form-data',
            }
          });
          Notification.complete('URLs upload successfully');
          return response.data;
        } catch (error) {
          console.error("Error uploading URLs:", error);
          Notification.error('Error uploading URLs');
        }
      };
      

      const deleteURLs = async (urlIds) => {
        const token = getToken();
        if (!token) {
          Notification.error('User is not authenticated', 'Please reload the page or logout and try again.');
          return;
        }
      
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}delete_urls/`, { url_ids: urlIds }, {
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            }
          });
          Notification.complete('URLs deleted successfully');
          return response.data;
        } catch (error) {
          console.error("Error deleting URLs:", error);
          Notification.error('Error deleting URLs');
        }
      };

    
    const getSessionDetails = async () => {
        const token = getToken(); // Assume this function retrieves the current user's auth token
        if (!token) {
            console.error('User is not authenticated');
            return null;
        }
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}session_details/`, {
                headers: {
                    'Authorization': `Token ${token}`
                },
            });

            setSessionDetails(response.data);
            return response.data;
        } catch (error) {
            console.error('Error getting session details:', error.response ? error.response.data : error);
            return null;
        }
    };

    const getReachOutDetails = async (state) => {
      const token = getToken();
      if (!token) {
          console.error('User is not authenticated');
          return null;
      }

      try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}reachout_details?state=${state}`, {
              headers: {
                  'Authorization': `Token ${token}`
              },
          });

          return response.data;
      } catch (error) {
          console.error('Error getting reach out details:', error.response ? error.response.data : error);
          return null;
      }
  };    
  
  const getReachOutReachEmail = async (urlId, emailTone) => {
    const token = getToken();
    if (!token) {
        console.error('User is not authenticated');
        return null;
    }

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}outreach_email?url_id=${urlId}&email_tone=${emailTone}`, {
            headers: {
                'Authorization': `Token ${token}`
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error getting reach out details:', error.response ? error.response.data : error);
        return null;
    }
};   

    const getSystemSessions = async () => {
      const token = getToken(); // Assume this function retrieves the current user's auth token
      if (!token) {
          console.error('User is not authenticated');
          return null;
      }
    
      try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}research_sessions/`, {
              headers: {
                  'Authorization': `Token ${token}`
              },
          });    
          return response.data;
      } catch (error) {
          console.error('Error getting session details:', error.response ? error.response.data : error);
          return null;
      }
    };   

    const pollSessionDetails = useCallback(async () => {
        if (isPolling) return; // Prevent multiple polling instances
      
        setIsPolling(true);
        try {
          while (true) {
            const details = await getSessionDetails();
            if (details.every(detail => detail.state !== 1)) {
              // Stop polling if no entry is in PROCESSING state
              break;
            }
            await new Promise(resolve => setTimeout(resolve, 5000));
          }
        } catch (error) {
          console.error('Error polling session details:', error);
        } finally {
          setIsPolling(false);
        }
    }, [isPolling]);
    
    const getBusinessContext = async () => {
      const token = getToken(); // Assume this function retrieves the current user's auth token
    
      if (!token) {
          console.error('User is not authenticated');
          return null;
      }
    
      try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}business_context/`, {
              headers: {
                  'Authorization': `Token ${token}`
              }
          });
    
          setBusinessContext(response.data);
          return response.data;
      } catch (error) {
          if (error.response && error.response.status === 404) {
              navigate('/getting_started');
          } else {
            console.error(error)
            Notification.error('Something went wrong');
          }
          return null;
      }
    }; 
  
  const createBusinessContext = async (formData) => {
    const token = getToken(); // Assume this function retrieves the current user's auth token
    if (!token) {
        console.error('User is not authenticated');
        return;
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}create_business_context/`, formData, {
            headers: {
                'Authorization': `Token ${token}`,
                // Don't set Content-Type here; let the browser do it to correctly handle the boundary in multipart/form-data
            }
        });
        Notification.complete('Profile saved successfully.');
        return response.data;
    } catch (error) {
        console.error('Error creating business context:', error.response ? error.response.data : error);
        Notification.error('Error creating profile.');
    }
  };

  const updateBusinessContext = async (formData) => {
    const token = getToken(); // Assume this function retrieves the current user's auth token
    if (!token) {
        console.error('User is not authenticated');
        return;
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}update_business_context/`, formData, {
            headers: {
                'Authorization': `Token ${token}`,
                // Don't set Content-Type here; let the browser do it to correctly handle the boundary in multipart/form-data
            }
        });
        Notification.complete('Profile saved successfully.');
        return response.data;
    } catch (error) {
        console.error('Error updating business context:', error.response ? error.response.data : error);
        Notification.error('Error updating profile.');
    }
  };  

  const updateTask = async (formData) => {
    const token = getToken(); // Assume this function retrieves the current user's auth token
    if (!token) {
        console.error('User is not authenticated');
        return;
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}tasks/update/`, formData, {
            headers: {
                'Authorization': `Token ${token}`,
                // Don't set Content-Type here; let the browser do it to correctly handle the boundary in multipart/form-data
            }
        });
        Notification.complete('Profile saved successfully.');
        return response.data;
    } catch (error) {
        console.error('Error creating business context:', error.response ? error.response.data : error);
        Notification.error('Error creating profile.');
    }
  };  

  const getIntegrationStatus = async () => {
    const token = getToken();
    if (!token) {
        console.error('User is not authenticated');
        return;
    }

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}integration/status/`, {
            headers: {
                'Authorization': `Token ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching integration status:', error.response ? error.response.data : error);
        Notification.error('Error fetching integration status.');
    }
  };

  const disconnectIntegration = async (integration) => {
      const token = getToken();
      if (!token) {
          console.error('User is not authenticated');
          return;
      }

      try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}integration/disconnect/`, { integration }, {
              headers: {
                  'Authorization': `Token ${token}`
              }
          });
          Notification.complete(`${integration} integration disconnected successfully.`);
          return response.data;
      } catch (error) {
          console.error('Error disconnecting integration:', error.response ? error.response.data : error);
          Notification.error('Error disconnecting integration.');
      }
  };

  const getEmailContext = async () => {
      const token = getToken();
      if (!token) {
          console.error('User is not authenticated');
          return;
      }

      try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}email/context/`, {
              headers: {
                  'Authorization': `Token ${token}`
              }
          });
          return response.data;
      } catch (error) {
          console.error('Error fetching email context:', error.response ? error.response.data : error);
          Notification.error('Error fetching email context.');
      }
  };

  const createOrUpdateEmailContext = async (emailContext) => {
      const token = getToken();
      if (!token) {
          console.error('User is not authenticated');
          return;
      }

      try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}email/context/update/`, { email_context: emailContext }, {
              headers: {
                  'Authorization': `Token ${token}`
              }
          });
          Notification.complete('Email context updated successfully.');
          return response.data;
      } catch (error) {
          console.error('Error updating email context:', error.response ? error.response.data : error);
          Notification.error('Error updating email context.');
      }
  };  

  const cleanupSessions = async (session_id) => {
    const token = getToken();
    if (!token) {
      console.error("User is not authenticated!");
      return null;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}delete_session/`, 
        { 'session_id': session_id },
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error setting user credits:", error);
      Notification.error('Error setting user credits');
      return null;
    }
  };    

  const handleError = (error) => {
    if (error.response && error.response.status === 401) {
        handleLogout();
        navigate('/');
        Notification.error('Session expired. Please log in again.');
    }

    if (error.response && error.response.status === 402) {
      Notification.error('Not enough credit.');
    }      
  };    

  return {
      analyzeURLs,
      initiateSystemTask,
      createBusinessContext,
      updateBusinessContext,      
      getBusinessContext,
      getSessionDetails,
      pollSessionDetails,
      createNewSession,
      uploadNewURLs,
      deleteURLs,
      businessContext,
      setBusinessContext,
      sessionDetails,
      setSessionDetails,
      systemTasks,
      getReachOutDetails,
      getReachOutReachEmail,
      sessions,
      setSessions,
      pollSystemTasks,
      getSystemTasks,
      getSystemSessions,
      getIntegrationStatus,
      getEmailContext,
      getCampaignOverview,
      resumeAnalysisTask,
      stopAnalysisTask,
      createOrUpdateEmailContext,
      disconnectIntegration,
      approveAndSendInitialMessage,
      cleanupSessions,
      exportCSV,
      exportHubSpot,
      updateTask,
      pollTime,
  };
};

export default useDocumentPageHook;

