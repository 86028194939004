import React from 'react';
import { Form, Input, Button } from 'antd';

const { TextArea } = Input;

const CompanyInfoForm = ({ form }) => (
  <Form form={form} layout="vertical">
    <Form.Item 
      name="businessName" 
      label="Company's Name" 
      rules={[{ required: true, message: "Please input your company's name!" }]}
    >
      <Input placeholder="Enter company's name" />
    </Form.Item>
    <Form.Item 
      name="businessCompanyUrl" 
      label="Company's Website"
      rules={[{ required: true, message: "Please provide your company's website!" }]}
    >
      <Input placeholder="Enter company's website URL" />
    </Form.Item>
    <Form.Item 
      name="businessDescription" 
      label="Company's Description"
      rules={[{ required: true, message: "Please describe what your company does!" }]}
    >
      <TextArea 
        placeholder="What does your company do?" 
        maxLength={500} 
        showCount 
      />
    </Form.Item>
    <Form.Item 
          name="businessLinkedin" 
          label="Business's LinkedIn"
        >            
          <Input 
            placeholder="Enter business's LinkedIn URL if present" 
          />
        </Form.Item>    
  </Form>
);

export default CompanyInfoForm;
