import React, { useState, useEffect } from 'react';
import { Popover, Button, Card, Select, Statistic, Modal } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import Notification from '../Notification/NotificationMessage';
import './ResumeTaskPopover.css'

const { Option } = Select;

const ResumeTaskPopover = ({ onResumeTask, maxURLCount, isProcessing, outOfCredit, urlsToAnalyze, analyzedURLs, sessionId }) => {
    const navigate = useNavigate();
    const [urlCount, setUrlCount] = useState(1);
    const [isInitiating, setIsInitiating] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [totalUrlsToAnalyze, setTotalUrlsToAnalyze] = useState(urlsToAnalyze);
    const [currentProgress, setCurrentProgress] = useState(`${analyzedURLs} / ${urlsToAnalyze}`);
  
    const handleResume = async () => {
      setIsInitiating(true);
      await onResumeTask(sessionId, urlCount);
      setIsInitiating(false);
      setModalVisible(false); // Close the modal on successful initiation
    };
  
    const handleChange = value => {
      setUrlCount(value);
    };
  
    const showModal = () => {
      setModalVisible(true);
    };
  
    const handleCancel = () => {
      setModalVisible(false);
    };
  
    return (
      <>
        <Button type="primary" icon={<PlayCircleOutlined />} onClick={showModal} disabled={isProcessing}>
          Resume
        </Button>
        <Modal
          title="Resume Task"
          visible={modalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="resume" type="primary" onClick={handleResume} disabled={isInitiating || totalUrlsToAnalyze === 0 || outOfCredit}>
              Resume and analyze selected URLs ({urlCount})
            </Button>,
          ]}
        >
          <Card bordered={false}>
            <p style={{ marginTop: 0, marginBottom: 15 }}>
              Resume the task with current campaign. Note that analyzed companies will not be rerun.
            </p>
            <Statistic title="Current Progress:" value={currentProgress} />
            <p>Select URLs to analyze until paused:</p>
            <Select defaultValue={1} style={{ width: '100%', marginBottom: 15 }} onChange={handleChange}>
              {[1, 10, 50, 100, 500, 1000].map(value => (
                <Option key={value} value={value}>
                  {value} URL{value > 1 ? 's' : ''}
                </Option>
              ))}
            </Select>
          </Card>
        </Modal>
      </>
    );
};

export default ResumeTaskPopover;
