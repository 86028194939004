import React, { useState, useEffect } from 'react';
import { Input, Button, Form, Card, Switch, Spin, Modal, Table, Divider, Row, Col, message } from 'antd';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';

const AdminPage = () => {
  const { 
    cleanupSessions, 
    addAllowedUser, 
    setUserCredits, 
    profile, 
    retryFailedJobs,
    getProfileForUser,
    updateSesionQuota,
    exportCampaign, 
    importCampaign, 
    setPremiumUser, 
    fetchUserProfile,
    fetchResearchSessions,
    resumeSession,
    addUserTag,
    addUser,
    startSchedule,
    deleteSchedule,
  } = useProfilePageHook();
  
  const [email, setEmail] = useState('');
  const [tagName, setTagName] = useState('');

  const [credits, setCredits] = useState({ max_campaigns: null, daily_generation_quota: null, qualification_credit: null, generation_credit: null });
  const [sessionThreshold, setSessionThreshold] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isPremium, setIsPremium] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const [minute, setMinute] = useState(null);
  const [userStatus, setUserStatus] = useState(null);

  const [userStatusData, setUserStatusData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false); 
  const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);

  const handleUserModalClose = () => {
    setIsStatusModalVisible(false);
  };   

  const loadProfile = async () => {
    const userProfile = await fetchUserProfile();
    setUserStatus(userProfile?.user_profile?.user_status)
    setIsPremium(userProfile?.user_profile?.user_status?.enrich_with_advanced_data)
    setLoading(false);
  };

  useEffect(() => {
    loadProfile();
  }, []);  

  if (loading) {
    return <Spin tip="Loading profile..." />;
  }

  const handleStartSchedule = () => {
    startSchedule(minute);
  };
  
  const handleDeleteSchedule = () => {
    deleteSchedule();
  };  

  const handleAddAllowedUser = () => {
    addAllowedUser(email);
  };

  const handleRegisterUser = () => {
    addUser(email);
  };  

  const handleSetUserCredits = () => {
    setUserCredits(email, credits);
  };

  const handleUpdateSessionQuota = () => {
    updateSesionQuota(email, sessionThreshold, credits);
  };  

  const handleAddUserTag = async () => {
    await addUserTag(email, tagName);
  };
  
  const showUserStatus = async () => {
    const userProfile = await getProfileForUser(email);
    const statusData = Object.entries(userProfile?.user_profile?.user_status || {}).map(([key, value], index) => ({
      key: index,  // Unique key for React list rendering
      name: key,
      status: value ? 'Active' : 'Inactive'  // Convert boolean to readable format
    }));
    setUserStatusData(statusData);  // Assuming you have a state setUserStatusData to store the table data
    setIsStatusModalVisible(true);  // Open the modal
  };
  

  const handleRetryFailedJobs = () => {
    retryFailedJobs(email, sessionThreshold);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleImportCampaign = () => {
    if (selectedFile) {
      importCampaign(email, selectedFile);
    } else {
      console.error("No file selected!");
    }
  };

  const handleViewUserSessions = async () => {
    const sessionsData = await fetchResearchSessions(email);
    setSessions(sessionsData);
    setIsModalVisible(true);
  };

  const userColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    }
  ];  


  const sessionStateMapping = {
    0: 'Default',
    1: 'Running',
    2: 'Paused',
    3: 'Completed',
    4: 'Failed',
    5: 'Aborted'
  };   

  const columns = [
    { title: 'Session ID', dataIndex: 'session_id', key: 'session_id' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Upload Date', dataIndex: 'upload_date', key: 'upload_date' },
    { title: 'State', dataIndex: 'session_state', key: 'session_state', render: (state) => sessionStateMapping[state] || 'Unknown'},
  ];
  
  // State descriptions based on the enum values from the backend
  const getStateDescription = (state) => {
    const stateDescriptions = {
      0: 'Default',
      1: 'Processing',
      2: 'Complete',
      3: 'Fail',
      4: 'Contact Not Found',
      5: 'Email Not Found',
      6: 'Profile Incomplete',
      7: 'Website Error',
      8: 'Reachout Error',
      9: 'Reachout Complete',
      10: 'Out of Credit',
      11: 'Out of Quota',
      12: 'IRRLEVANT',
    };
    return stateDescriptions[state] || 'Unknown State';
  };

  const expandedRowRender = (record) => {
    const urlColumns = [
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: state => getStateDescription(state),
        sorter: (a, b) => a.state - b.state,  // Enable sorting based on the state integer
      },
      { title: 'URL ID', dataIndex: 'url_id', key: 'url_id' },
      { title: 'First Name', dataIndex: 'first_name', key: 'first_name' },
      { title: 'Last Name', dataIndex: 'last_name', key: 'last_name' },
      {
        title: 'Email', 
        dataIndex: 'contact_email', 
        key: 'contact_email', 
        render: email => (
          <div style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {email}
          </div>
        )
      },
      {
        title: 'LinkedIn', 
        dataIndex: 'linkedin', 
        key: 'linkedin', 
        render: linkedin => (
          <a href={linkedin} target="_blank" rel="noopener noreferrer" style={{ maxWidth: '150px', display: 'inline-block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {linkedin}
          </a>
        )
      },
      {
        title: 'Company Domain', 
        dataIndex: 'company_domain', 
        key: 'company_domain',
        render: domain => (
          <div style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {domain}
          </div>
        )
      },
      { title: 'Description', dataIndex: 'state_details', key: 'state_details' }
    ];
  
    return (
      <Table 
        columns={urlColumns} 
        dataSource={record.urls} 
        pagination={{ pageSize: 20 }}  // Set pagination with 20 rows per page
        rowKey="url_id"
      />
    );
  };

  

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  if (loading) {
    return <Spin tip="Loading profile..." />;
  }

  const handleExportCampaign = () => {
    exportCampaign(email);
  };

  const handleDeleteSession = () => {
    cleanupSessions(email, sessionThreshold, true);
  };

  const handleAbortSession = () => {
    cleanupSessions(email, sessionThreshold, false);
  };  

  const handleResumeSession  = () => {
    resumeSession(email, sessionThreshold);
  }

  const handleSetPremiumUser = async () => {
    if (isPremium) {
      await setPremiumUser(email, false);
      message.info('disable premium user')
    }
    else {
      await setPremiumUser(email, true);
      message.info('enable premium user')
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      {profile.isAdmin ? (
        <Card title="Admin Page" bordered={false}>
          <Form layout="vertical">

          {/* User Info & Session Management Section */}
          <Divider orientation="left">User Info & Session Management</Divider>
          <Row gutter={16}>
            {/* User Info */}
            <Col span={12}>
              <Form.Item label="Email">
                <Input value={email} onChange={(e) => setEmail(e.target.value)} />
              </Form.Item>

              {/* Action buttons group */}
              <Form.Item>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '10px' }}>
                  <Button type="primary" onClick={handleViewUserSessions}>View User Sessions</Button>
                  <Button type="primary" onClick={handleRegisterUser}>Register User</Button>
                  <Button type="primary" onClick={handleAddAllowedUser}>Add Allowed User</Button>
                  <Button type="primary" onClick={handleSetPremiumUser}>Toggle Premium User</Button>
                  <Button type="primary" onClick={handleExportCampaign}>Export Campaign</Button>
                </div>

                {/* Import Campaign Section */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                  <Form.Item label="Import Campaign" style={{ marginBottom: 0 }}>
                    <input type="file" onChange={handleFileChange} />
                  </Form.Item>
                  <Button type="primary" onClick={handleImportCampaign}>Import Campaign</Button>
                </div>
              </Form.Item>
            </Col>


            {/* Session Management */}
            <Col span={12}>
              <Form.Item label="Session ID">
                <Input type="number" value={sessionThreshold} onChange={(e) => setSessionThreshold(e.target.value)} />
              </Form.Item>
              <Form.Item>
                <Button danger type="primary" onClick={handleResumeSession}>Resume Session</Button>
                <Button danger type="primary" onClick={handleDeleteSession} style={{ marginLeft: '10px' }}>Delete Session</Button>
                <Button danger type="primary" onClick={handleAbortSession} style={{ marginLeft: '10px' }}>Abort Session</Button>

                <Button danger type="primary" onClick={handleRetryFailedJobs} style={{ marginLeft: '10px' }}>Retry Failed Jobs</Button>
              </Form.Item>
            </Col>
          </Row>

          {/* Credits Section */}
          <Divider orientation="left">User Credits</Divider>
          <Row gutter={16}>
            {/* Left column: Max Campaigns */}
            <Col span={12}>
              <Form.Item label="Max campaigns">
                <Input type="number" value={credits.max_campaigns} onChange={(e) => setCredits({ ...credits, max_campaigns: e.target.value })} />
              </Form.Item>

              <Form.Item label="Daily generation quota">
                <Input type="number" value={credits.daily_generation_quota} onChange={(e) => setCredits({ ...credits, daily_generation_quota: e.target.value })} />
              </Form.Item>
              <Form.Item label="Qualification Credit">
                <Input type="number" value={credits.qualification_credit} onChange={(e) => setCredits({ ...credits, qualification_credit: e.target.value })} />
              </Form.Item>
              <Form.Item label="Monthly generation credits">
                <Input type="number" value={credits.generation_credit} onChange={(e) => setCredits({ ...credits, generation_credit: e.target.value })} />
              </Form.Item>
              <Button type="primary" onClick={handleSetUserCredits}>Set User Credits</Button>
              <Button type="primary" onClick={handleUpdateSessionQuota} style={{ marginLeft: '10px' }}>Update session quota</Button>


            </Col>

            <Col span={12}>
            <Form.Item label="Custom Tag Name">
              <Input value={tagName} onChange={(e) => setTagName(e.target.value)} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={handleAddUserTag}>Toggle User Tag</Button>
              <Button type="primary" onClick={showUserStatus}>Show user status</Button>

              <p style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
                "ENABLED_AUTOMATED_SESSION": If enabled, will run automated session every day
              </p>
              <p style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
                "DEMO_USER": If enabled, will run less leads `5` every batch with faster retrigger
              </p>
              <p style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
                "NEED_COMPANY_ORG_ENRICHMENT_V1": If enabled, will run apollo org enrichment agent.
              </p> 
              <p style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
                "ENRICH_MULTIPLE_CONTACTS_V1": If enabled, will run more than one contact `for now, it's 5`.
              </p> 
              <p style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
                "ENRICH_COMPANY_WITH_WEB": If enabled, will enrich company with website.
              </p> 
              <p style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
                "ONE_TIME_TEST": If enabled, will only run analysis batch once without requeuing the same task after X minute.
              </p>                                                                      
            </Form.Item>
          </Col>
          </Row>

          {/* Credits Section */}
          <Divider orientation="left">Scheduler</Divider>
          <Row gutter={16}>
            {/* Left column: Max Campaigns */}
            <Col span={12}>
              <Form.Item label="Minute">
                <Input type="number" value={minute} onChange={(e) => setMinute(e.target.value)} />
              </Form.Item>
              <Button type="primary" onClick={handleStartSchedule}>Start Schedule</Button>
              <Button type="primary" onClick={handleDeleteSchedule} style={{ marginLeft: '10px' }}>Delete Schedule</Button>
            </Col>
          </Row>          
          

          </Form>
     

          <Modal
            title="Research Sessions"
            visible={isModalVisible}
            onCancel={handleModalClose}
            width={1500}
            footer={[
              <Button key="close" onClick={handleModalClose}>
                Close
              </Button>
            ]}
          >
            <Table 
              columns={columns} 
              dataSource={sessions} 
              rowKey="session_id"
              expandedRowRender={expandedRowRender}
            />

          </Modal>

          <Modal
            title="User Status"
            visible={isStatusModalVisible}
            onCancel={handleUserModalClose}
            width={500}  // Adjust width as needed
            footer={[
              <Button key="close" onClick={handleUserModalClose}>
                Close
              </Button>
            ]}
          >
            <Table 
              columns={userColumns} 
              dataSource={userStatusData}  // This assumes you've set this state in your showUserStatus
              pagination={false}  // Assuming there won't be too many statuses to require pagination
            />
          </Modal>
         
        </Card>
      ) : (
        <p>You do not have permission to access this page.</p>
      )}
    </div>
  );
};

export default AdminPage;
