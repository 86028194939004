import React, { useEffect, useState, useRef } from 'react';
import { getToken } from '../Login/AuthContext';
import { Layout, Menu, Modal, Spin, Breadcrumb } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { MonitorOutlined, RocketOutlined, CheckCircleOutlined, MailFilled, UnorderedListOutlined, PlayCircleOutlined, MailOutlined, TeamOutlined, ToolOutlined, CloudOutlined, UserOutlined  } from '@ant-design/icons';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';

import UserProfileDropdown from '../ProfilePage/ProfilePage';
import ContactUsPage from '../ContactUsPage/ContactUsPage';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const MainLayout = ({ hideSider, disableScroll, children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { selectDocument } = useDocumentPageHook(); // Use your context here
    const { fetchUserProfile, profile } = useProfilePageHook();    
    const [isLoading, setIsLoading] = useState(false);
    const [isTokenAvailable, setIsTokenAvailable] = useState(!!getToken());
    const [contactModalVisible, setContactModalVisible] = useState(false);
    
    useEffect(() => {        
        if (!isTokenAvailable) {
            navigate('/');
        } else {
            setIsTokenAvailable(!!getToken());
        }
        const loadProfile = async () => {
            await fetchUserProfile();
          };
          loadProfile();        
    }, [isTokenAvailable, navigate]);

    const showContactModal = () => {
        setContactModalVisible(true);
    };  
    
    useEffect(() => {
        window.scrollTo(0, 0); // This scrolls the window to the top-left corner of the document.
    }, [location.pathname]); // This effect runs every time the pathname changes.    
    
    let selectedKey;
    switch (location.pathname) {
        case '/settings/company':
            selectedKey = "company";
            break; 
        case '/settings/integrations':
            selectedKey = "integrations";
            break;             
        case '/campaign/tasks':
            selectedKey = "tasks";
            break;  
        case '/campaign/leads':
            selectedKey = "leads";
            break;                          
        case '/campaign/setup':
            selectedKey = "setup";
            break;   
        case '/campaign/workspace':
            selectedKey = "workspace";
            break;                                                      
        case '/contact_us':
            selectedKey = "5";
            break;   
        case '/usage':
            selectedKey = "usage";
            break;               
        case '/outreach/setup':
            selectedKey = "email_setup";
            break;             
        case '/outreach/inbox':
            selectedKey = "inbox";
            break;   
        case 'outreach/pending_approval':
            selectedKey = "pending_approval";
            break;                                     
        default:
            selectedKey = "0";
    }

    // Helper function to generate breadcrumb items based on the current path
    const generateBreadcrumb = () => {
        const pathSnippets = location.pathname.split('/').filter(i => i);
        const pathArray = pathSnippets.map((_, index) => {
            const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
            return { url, title: pathSnippets[index] }; // The title should be more user-friendly, possibly replaced with a mapping
        });
        return pathArray;
    };

    function capitalizeFirstLetter(string) {
        if (!string) return ''; // Handle empty string case
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // Generate breadcrumb items
    const breadcrumbItems = generateBreadcrumb().map((snippet, index, arr) => (
        <Breadcrumb.Item key={index}>
            <span style={{ marginRight: '3px' }}>{<UnorderedListOutlined />}</span>
            {index === arr.length - 1 || index === 0 ? (
                <span>{capitalizeFirstLetter(snippet.title)}</span>
            ) : (
                <Link to={snippet.url}>{capitalizeFirstLetter(snippet.title)}</Link>
            )}
        </Breadcrumb.Item>
    ));
  
    return (
        <Layout style={{ minHeight: '100vh', overflow: 'hidden' }}>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%', height: '64px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 16px', boxSizing: 'border-box', backgroundColor: '#001529'}}>
                <div style={{
                    width: '120px', // Adjust size as needed
                    height: '31px', // Adjust size as needed
                    background: `url('/logo_white.png') no-repeat center center`,
                    backgroundSize: 'contain', // Adjust size as needed
                }} />
                <UserProfileDropdown />
            </Header>            
            {!hideSider && (
                <Sider width={200} style={{ height: '100vh', position: 'fixed', left: 0, top: 0, marginTop: '64px', backgroundColor: '#F0F0F0' }}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Menu mode="inline" style={{ minHeight: '100%', borderRight: 0, backgroundColor: '#F0F0F0' }} selectedKeys={[selectedKey]} defaultOpenKeys={["settings", "campaign"]}>
                    <Menu.SubMenu key="settings" title="Settings">
                        <Menu.Item key="company">
                            <Link to="/settings/company">
                                <TeamOutlined />
                                <span style={{fontWeight: 'bold'}}>Company</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="integrations">
                            <Link to="/settings/integrations">
                                <CloudOutlined />
                                <span style={{fontWeight: 'bold'}}>Integrations</span>
                            </Link>
                        </Menu.Item>                        
                    </Menu.SubMenu>

                    <Menu.SubMenu key="campaign" title="Campaign">
                        <Menu.Item key="setup">
                            <Link to="/campaign/setup">
                                <ToolOutlined />
                                <span style={{fontWeight: 'bold'}}>Setup</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="tasks">
                            <Link to="/campaign/tasks">
                                <PlayCircleOutlined />
                                <span style={{fontWeight: 'bold'}}>Tasks</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="leads">
                            <Link to="/campaign/leads">
                                <MonitorOutlined />
                                <span style={{fontWeight: 'bold'}}>Leads</span>
                            </Link>
                        </Menu.Item>                                                
                    </Menu.SubMenu>
                    {/* <Menu.Item key="usage" icon={<BarChartOutlined />}>
                        <Link to="/usage">
                            <span style={{fontWeight: 'bold'}}>Usage</span>
                        </Link>
                    </Menu.Item>                       */}

                    {profile.isAdmin && (
                    <Menu mode="inline" style={{ minHeight: '100%', borderRight: 0, backgroundColor: '#F0F0F0' }} selectedKeys={[selectedKey]} defaultOpenKeys={["outreach"]}>
                    <Menu.SubMenu key="outreach" title="Outreach">
                        <Menu.Item key="email_setup" icon={<ToolOutlined />} >
                        <Link to="/outreach/setup">
                            <span style={{fontWeight: 'bold'}}>Setup</span>
                        </Link>
                        </Menu.Item>    
                        <Menu.Item key="pending_approval" icon={<CheckCircleOutlined />}>
                        <Link to="/outreach/pending_approval">
                            <span style={{fontWeight: 'bold'}}>Pending Inbox</span>
                        </Link>
                        </Menu.Item>  
                        <Menu.Item key="inbox" icon={<MailOutlined />}>
                        <Link to="/outreach/inbox">
                            <span style={{fontWeight: 'bold'}}>Inbox</span>
                        </Link>
                        </Menu.Item>    
                        </Menu.SubMenu>                                          

                    </Menu>  
                    )}                                     

                    <Menu.Item key="5" icon={<UserOutlined />} onClick={showContactModal}>
                        <span style={{fontWeight: 'bold'}}>Contact Us</span>
                    </Menu.Item>                          

                    </Menu>
                </div>
                </Sider>
            )}            
            <Layout style={{ 
                marginLeft: hideSider ? '5%' : '200px', 
                marginRight: hideSider ? '5%' : '0px', 
                paddingTop: '64px', 
                transition: 'margin-left 0.2s' // Smooth transition for sider toggle
            }}>
            <Breadcrumb style={{ margin: '16px' }}>
                {breadcrumbItems}
            </Breadcrumb>                
            <Content style={{ 
                display: 'flex', 
                paddingTop: '64px', 
                overflowY: disableScroll ? 'hidden' : 'scroll', // Add scroll if content is taller than the screen
                height: '100%', // Make sure Content takes full height
                padding: '1%', // Allows for some space around the content on smaller screens
            }}
            >
                {isLoading ? (
                <Modal title="Loading" visible={isLoading} footer={null} closable={false}>
                    <Spin size="large" />
                </Modal>
                ) : (
                <div style={{ 
                    padding: 0,
                    width: '100%', // Ensure the div takes up the container width
                    height: '100%', // Ensure the div takes up the container height
                    display: 'flex', // Use flex to center the child div
                    justifyContent: 'center',
                }}>
                    {children}
                </div>
                )}
            </Content>
            </Layout>
            <ContactUsPage visible={contactModalVisible} onClose={() => setContactModalVisible(false)} />
        </Layout>
    );
};

export default MainLayout;

