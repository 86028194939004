import React from 'react';
import { notification } from 'antd';

const openErrorNotification = (message = 'An error occurred!', description = 'Please try again later') => {
    notification.error({
        message,
        description,
    });
};

// New function for complete notification
const openCompleteNotification = (section, file_name) => {
  notification.success({
    message: `${section}.`,
  });
};

const Notification = {
    error: openErrorNotification,
    complete: openCompleteNotification // Add complete notification method
};

export default Notification;
